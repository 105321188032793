import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import classNames from 'classnames';

import Loading from '../../../Loading';

function ShopItemUI(props) {
    const classes = classNames({
        'shop-item': true,
        'is-loading': props.isLoading,
        'is-disabled': props.isDisabled,
    });

    return (
        <div key={props.key} className={classes} onClick={props.onClick}>
            <div className="title">{props.title}</div>
            <div className="description">{props.description}</div>
            <Loading />
        </div>
    );
}

ShopItemUI.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    key: PropTypes.string.isRequired,
};

export default ShopItemUI;
