import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PageDefaultUI from '../../../components/Pages/Default';
import { push } from 'connected-react-router';
import { URL_RATING, URL_GAME, URL_SHOP, URL_PROFILE } from '../../../config/url';

import { GAME_TYPE_SINGLE, GAME_TYPE_RANDOM, GAME_TYPE_FRIEND } from '../../../config';

class PageDefault extends PureComponent {
    constructor(props) {
        super(props);

        this.handleRatingPageClick = this.handleRatingPageClick.bind(this);
        this.handleShopPageClick = this.handleShopPageClick.bind(this);
        this.handleProfilePageClick = this.handleProfilePageClick.bind(this);
        this.handleRandomGamePageClick = this.handleRandomGamePageClick.bind(this);
        this.handleFriendGamePageClick = this.handleFriendGamePageClick.bind(this);
        this.handleSingleGamePageClick = this.handleSingleGamePageClick.bind(this);
    }

    render() {
        return (
            <PageDefaultUI
                user={this.props.user}
                onRatingPageClick={this.handleRatingPageClick}
                onShopPageClick={this.handleShopPageClick}
                onProfilePageClick={this.handleProfilePageClick}
                onRandomGamePageClick={this.handleRandomGamePageClick}
                onFriendGamePageClick={this.handleFriendGamePageClick}
                onSingleGamePageClick={this.handleSingleGamePageClick}
            />
        );
    }

    handleRatingPageClick() {
        this.props.actions.toUrl(URL_RATING);
    }

    handleShopPageClick() {
        this.props.actions.toUrl(URL_SHOP);
    }

    handleProfilePageClick() {
        this.props.actions.toUrl(URL_PROFILE);
    }

    handleRandomGamePageClick() {
        this.props.actions.toUrl(`${URL_GAME}/${GAME_TYPE_RANDOM}`);
    }

    handleFriendGamePageClick() {
        this.props.actions.toUrl(`${URL_GAME}/${GAME_TYPE_FRIEND}`);
    }

    handleSingleGamePageClick() {
        this.props.actions.toUrl(`${URL_GAME}/${GAME_TYPE_SINGLE}`);
    }
}

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                toUrl: push,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageDefault);
