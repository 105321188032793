import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image as NativeImage } from 'react-native';

import PageCountDownUI from '../../../../components/Pages/Game/Countdown';
import PlayFieldUI from '../../../../components/Pages/Game/PlayField';

import SingleGamePanel from './SingleGamePanel';
import MultiplayerGamePanel from './MultiplayerGamePanel';
import HelpersPanel from './HelpersPanel';

import {
    EVENT_CLIENT_GAME_PLAYER_MOVE,
    EVENT_CLIENT_GAME_PLAYER_READY,
    EVENT_SERVER_GAME_NEW_ROUND,
    EVENT_SERVER_GAME_ROUND_RESULT,
    EVENT_CLIENT_GAME_LEAVE,
    EVENT_SERVER_GAME_HELPER_DATA,
    EVENT_CLIENT_GAME_PLAYER_OPEN_ITEM,
    EVENT_SERVER_GAME_PLAYER_OPEN_ITEM,
    EVENT_SERVER_GAME_ACTIVE_USER_ID
} from '../../../../config/socket';

import { setRound, setPlayUser, removeUsersStatistic, setActiveUserId } from '../../../../store/game';
import PlayUser from '../../../../model/playUser';
import RoundModel from '../../../../model/round';
import { SHOW_3_SEC, OPEN_ONE_PAIR } from '../../../../config/helpers';
import { GAME_TYPE_RANDOM, GAME_TYPE_FRIEND, GAME_TYPE_SINGLE } from '../../../../config';
import { showNotification } from '../../../../store/notification';
import NotificationModel from '../../../../model/notification';
import { goToPage, TYPE_NATIVE } from '../../../../store/app';
import { URL_HOME } from '../../../../config/url';
import I18n from '../../../../i18n';
import { getPixelRatio } from '../../../../utils';

const OPEN_LIMIT = 2;
const VISIBLE_LIMIT_TIME = 700;
const ONE_SECOND = 1000;

class PlayGame extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            // Round values
            startGameCountdownTimer: 3,
            items: this.props.round.items,

            // All game values
            roundValue: 1
        };

        this.openedItems = [];
        this.isHelperActive = false;
        this.images = [];

        this.onNewRound = this.onNewRound.bind(this);
        this.onMoveResult = this.onMoveResult.bind(this);
        this.onHelperData = this.onHelperData.bind(this);
        this.onOpenItem = this.onOpenItem.bind(this);
        this.onSetActiveUserId = this.onSetActiveUserId.bind(this);
        this.handlePageClose = this.handlePageClose.bind(this);
    }

    componentDidMount() {
        this.setTimerValue();
        this.preloadRoundImages(this.state.items);

        this.props.socket.on(EVENT_SERVER_GAME_NEW_ROUND, this.onNewRound);
        this.props.socket.on(EVENT_SERVER_GAME_ROUND_RESULT, this.onMoveResult);
        this.props.socket.on(EVENT_SERVER_GAME_HELPER_DATA, this.onHelperData);
        this.props.socket.on(EVENT_SERVER_GAME_PLAYER_OPEN_ITEM, this.onOpenItem);
        this.props.socket.on(EVENT_SERVER_GAME_ACTIVE_USER_ID, this.onSetActiveUserId);
    }

    componentWillUnmount() {
        this.props.actions.removeUsersStatistic();
        this.props.actions.setActiveUserId(null);

        this.props.socket.off(EVENT_SERVER_GAME_NEW_ROUND, this.onNewRound);
        this.props.socket.off(EVENT_SERVER_GAME_ROUND_RESULT, this.onMoveResult);
        this.props.socket.off(EVENT_SERVER_GAME_HELPER_DATA, this.onHelperData);
        this.props.socket.off(EVENT_SERVER_GAME_PLAYER_OPEN_ITEM, this.onOpenItem);
        this.props.socket.off(EVENT_SERVER_GAME_ACTIVE_USER_ID, this.onSetActiveUserId);

        const userId = this.props.user.id;
        const gameId = this.props.game.id;

        this.props.socket.emit(EVENT_CLIENT_GAME_LEAVE, {
            gameId,
            userId
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.startGameCountdownTimer === 0 && this.state.startGameCountdownTimer > 0) {
            this.setTimerValue();
        }

        if (prevState.startGameCountdownTimer > 0 && this.state.startGameCountdownTimer === 0) {
            // New game started
            this.sendPlayerReady();
        }
    }

    preloadRoundImages(items) {
        if (this.props.appType === TYPE_NATIVE) {
            items.forEach((item) => {
                NativeImage.prefetch(item.imagePath);
            });
        } else {
            this.images = [];

            items.forEach((item, key) => {
                const image = new Image();
                image.src = item.imagePath;

                this.images[key] = image;
            });
        }
    }

    /**
     * Send player is ready when animation is finished
     */
    sendPlayerReady() {
        this.props.socket.emit(EVENT_CLIENT_GAME_PLAYER_READY, {
            gameId: this.props.game.id,
            userId: this.props.user.id
        });
    }

    /**
     * Send open item to show opened item in other's user field
     * @param position
     * @param openedItems
     */
    sendOpenItem(position, openedItems) {
        this.props.socket.emit(EVENT_CLIENT_GAME_PLAYER_OPEN_ITEM, {
            position,
            gameId: this.props.game.id,
            openerUserId: this.props.user.id,
            openedItems: openedItems
        });
    }

    /**
     * Send opened items to calculate statistic
     *
     * @param openedItems
     */
    sendPairsToServer(openedItems) {
        const userId = this.props.user.id;
        const gameId = this.props.game.id;

        this.props.socket.emit(EVENT_CLIENT_GAME_PLAYER_MOVE, {
            gameId,
            userId,
            pairs: openedItems
        });
    }

    render() {
        if (this.state.startGameCountdownTimer === 0) {
            return (
                <PlayFieldUI
                    gamePanel={this.renderGamePanel()}
                    helpersPanel={<HelpersPanel />}
                    items={this.getFieldItems()}
                    isActive={this.props.user.id === this.props.activeUserId}
                    onPageClose={this.props.game.type === GAME_TYPE_SINGLE && this.handlePageClose}
                />
            );
        } else {
            return <PageCountDownUI value={this.state.startGameCountdownTimer} />;
        }
    }

    getFieldItems() {
        return this.state.items.map(item => {
            return {
                key: `${this.state.roundValue}_${item.id}`,
                item,
                onClick: this.handleItemClick.bind(this, item)
            }
        })
    }

    renderGamePanel() {
        switch (this.props.game.type) {
            case GAME_TYPE_RANDOM:
            case GAME_TYPE_FRIEND:
                return (
                    <MultiplayerGamePanel
                        roundValue={this.state.roundValue}
                        playerUserStat={this.props.playerUserStat}
                        opponentUserStat={this.props.opponentUserStat}
                        activeUserId={this.props.activeUserId}
                    />
                );
            default:
                return (
                    <SingleGamePanel
                        roundValue={this.state.roundValue}
                        playerUserStat={this.props.playerUserStat}
                    />
                );
        }
    }

    setTimerValue() {
        if (this.state.startGameCountdownTimer > 0) {
            setTimeout(() => {
                this.setState({
                    startGameCountdownTimer: this.state.startGameCountdownTimer - 1
                });

                this.setTimerValue();
            }, ONE_SECOND);
        }
    }

    openItemByPosition(position) {
        const items = this.state.items.slice();
        const item = items[position];

        item.isOpened = true;
        items[position] = item;

        this.setState({
            items
        });

        this.openedItems.push(item);
    }

    processOpenedItems(openedItems) {
        if (openedItems[0].value === openedItems[1].value) {
            // We found pair
            this.commitFoundItems(openedItems);
            this.openedItems = [];
        } else {
            setTimeout(() => {
                this.hideNotFoundItems();
            }, VISIBLE_LIMIT_TIME);
        }
    }

    hideNotFoundItems() {
        const items = this.state.items.slice();

        items.forEach(item => {
            if (item.isFound === false) {
                item.isOpened = false
            }
        });

        this.setState({
            items
        });

        this.openedItems = [];
    }

    commitFoundItems(openedItems = []) {
        const items = this.state.items.slice();

        openedItems.forEach(item => {
            const foundItem = items.find(stateItem => stateItem.id === item.id);

            if (foundItem) {
                const position = items.indexOf(foundItem);

                foundItem.isFound = true;
                foundItem.isOpened = true;

                items[position] = foundItem;
            }
        });

        this.setState({ items });
    }

    /**
     *
     * @param data
     * @param {RoundModel} data.round
     */
    onNewRound(data) {
        const round = new RoundModel(data.round, getPixelRatio());
        this.props.actions.setRound(round);

        const items = round.items.slice();

        this.setState({
            roundValue: round.id,
            items
        });

        // Clear array
        this.openedItems = [];
        this.preloadRoundImages(items);
    }

    /**
     *
     * @param data
     * @param {PlayUserModel} data.playUser
     */
    onMoveResult(data) {
        const playerUser = new PlayUser(data.playUser);
        this.props.actions.setPlayUser(playerUser.userId, playerUser);
    }

    /**
     * Someone opened item. Sync field with other user
     * @param data
     * @param data.openerUserId
     * @param data.position
     * @param data.openedItems
     */
    onOpenItem(data) {
        if (data.openerUserId !== this.props.user.id) {
            // We should sync field only with opponent.
            // This code does not work in single play mode

            if (data.position) {
                // For magic helper we dont send position
                this.openItemByPosition(data.position);
            }

            if (data.openedItems.length === OPEN_LIMIT) {
                this.processOpenedItems(data.openedItems);
            }
        }
    }

    onSetActiveUserId(activeUserId) {
        this.props.actions.setActiveUserId(activeUserId);
        this.hideNotFoundItems();
    }

    /**
     * @param data
     * @param data.ok
     * @param data.helper
     */
    onHelperData(data) {
        if (data.ok === true) {
            this.props.actions.showNotification(
                new NotificationModel({
                    title: I18n.t('helperActivated'),
                    timeout: 1500
                })
            );

            const helperId = data.helper.id;

            switch (helperId) {
                case SHOW_3_SEC.id:
                    const items = this.state.items.slice();

                    items.forEach(item => {
                        if (item.isFound === false) {
                            item.isOpened = true
                        }
                    });

                    this.setState({
                        items
                    });

                    setTimeout(() => {
                        this.hideNotFoundItems();
                    }, 3000);

                    break;
                case OPEN_ONE_PAIR.id:
                    const notOpenFirstItem = this.state.items.find(
                        item => item.isOpened === false && item.isFound === false
                    );

                    if (notOpenFirstItem) {
                        const notOpenSecondItem = this.state.items.find(
                            item => item.value === notOpenFirstItem.value && item !== notOpenFirstItem
                        );

                        if (notOpenSecondItem) {
                            const openedItems = [notOpenFirstItem, notOpenSecondItem];
                            this.sendOpenItem(null, openedItems);
                            this.sendPairsToServer(openedItems);
                            this.commitFoundItems(openedItems);
                        }
                    }

                    break;
            }
        }
    }

    handleItemClick(item) {

        if (this.props.user.id !== this.props.activeUserId) {
            // Other user is playing
            console.log(`User is not active to make a move. User id ${this.props.user.id} and active user id ${this.props.activeUserId}`);
            return false;
        }

        if (this.isHelperActive === true) {
            // If user activated helper to show all items
            console.log(`Helper is active`);
            return false;
        }

        if (item.isOpened || item.isFound) {
            // Prevent double click on already opened items
            return false;
        }

        if (this.openedItems.length === OPEN_LIMIT) {
            // Prevent clicks when we have limit
            return false;
        }

        if (this.openedItems.indexOf(item) !== -1) {
            // If user click on same item do nothing
            return false;
        }

        const position = this.state.items.indexOf(item);

        if (position === -1) {
            return false;
        }

        this.openItemByPosition(position);
        this.sendOpenItem(position, this.openedItems);

        if (this.openedItems.length === OPEN_LIMIT) {
            this.sendPairsToServer(this.openedItems);
            this.processOpenedItems(this.openedItems);
        }
    }

    handlePageClose() {
        this.props.actions.goToPage(URL_HOME);
    }
}

PlayGame.propTypes = {};

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        game: state.game.game,
        round: state.game.round,
        socket: state.app.socket,
        playerUserStat: state.game.usersStatistic[state.game.game.firstUserId],
        opponentUserStat: state.game.usersStatistic[state.game.game.secondUserId],
        activeUserId: state.game.activeUserId,
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators({
            setRound,
            setPlayUser,
            removeUsersStatistic,
            setActiveUserId,
            showNotification,
            goToPage,
        }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayGame);
