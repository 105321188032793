import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import classNames from 'classnames';

function HomeUI(props) {
    const pageClasses = classNames({
        'page-content': true,
        'is-game-started': props.isGameStarted,
    });

    const homePageClasses = classNames({
        playing: props.isGameStarted,
    });

    const blackLineClasses = classNames({
        'black-line': true,
        'is-hidden': props.isGameStarted,
    });

    return (
        <React.Fragment>
            <div className={blackLineClasses} />
            <div id="home" className={homePageClasses}>
                {props.notification}
                <header>{props.header}</header>

                <div className={pageClasses}>
                    <aside>{props.sidebar}</aside>
                    <div className="content-wrapper">
                        <div className="content">{props.children}</div>
                        {props.footer ? <footer>{props.footer}</footer> : null}
                    </div>
                </div>
                {props.dialog}
            </div>
        </React.Fragment>
    );
}

HomeUI.propTypes = {
    header: PropTypes.element.isRequired,
    sidebar: PropTypes.element,
    footer: PropTypes.element,
    dialog: PropTypes.element,
    infoBlock: PropTypes.element,
    notification: PropTypes.element,
    isGameStarted: PropTypes.bool.isRequired,
};

export default HomeUI;
