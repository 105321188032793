import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Loading from '../../../Loading';

function PageGameLoadingUI(props) {
    return (
        <div id="page-game-loading" className="page">
            <div className={'loading-container'}>
                <Loading />
                <div className={'title'}>{props.titleText}</div>
                <div className={'description'}>{props.descriptionText}</div>
                {props.buttons}
                <div className={'additional'}>{props.additional}</div>
            </div>
        </div>
    );
}

PageGameLoadingUI.propTypes = {
    titleText: PropTypes.string,
    descriptionText: PropTypes.string,
    buttons: PropTypes.array,
    additional: PropTypes.element,
};

export default PageGameLoadingUI;
