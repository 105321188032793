import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import ReactTooltip from 'react-tooltip';

function LevelProgressUI(props) {
    const progress = Math.floor(
        (props.levelPoints * 100) / (props.levelNeedPoints + props.levelPoints)
    );

    return (
        <div className="user-level-progress">
            <div className="total">
                <div className="progress" style={{ width: `${progress}%` }}></div>
                <div className="points">{Math.round(progress)}%</div>
                <div className={'info-tooltip'}>
                    <img
                        className="icon"
                        data-tip
                        data-for="progress-tooltip"
                        src={'/images/icons/info.svg'}
                    />
                    <ReactTooltip id="progress-tooltip" place="bottom" type="light" effect="solid">
                        <span className={'tooltip'}>
                            Ваш прогресс до следующего уровня. <br />
                            Осталось {props.levelNeedPoints +
                                props.levelPoints -
                                props.levelPoints}{' '}
                            очков
                        </span>
                    </ReactTooltip>
                </div>
            </div>
            {props.totalPoints !== undefined ? (
                <div className="total-points">
                    <img className="icon" src={'/images/icons/star.svg'} alt={''} />
                    <span>Всего очков: {props.totalPoints}</span>
                </div>
            ) : null}
        </div>
    );
}

LevelProgressUI.propTypes = {
    levelPoints: PropTypes.number.isRequired,
    levelNeedPoints: PropTypes.number.isRequired,
    totalPoints: PropTypes.number,
};

export default LevelProgressUI;
