import PlayFieldItemModel from '../model/fieldItem';
import { Dimensions, Platform, PixelRatio } from 'react-native';

const X_WIDTH = 375;
const X_HEIGHT = 812;

export function getPixelRatio() {
    if (window && window.devicePixelRatio) {
        return window.devicePixelRatio;
    } else {
        return PixelRatio.get();
    }
}

export function getParameterByName(name, url) {
    // eslint-disable-next-line no-param-reassign
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-param-reassign
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function isWebMobile() {
    if (typeof window !== 'undefined') {
        const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

        return (
            (window.innerWidth <= 800 && window.innerHeight <= 600) ||
            toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            })
        );
    }

    return false;
}

/**
 * For offline mode
 *
 * @param length
 * @returns {*}
 */
export function generateItems(length) {
    function shuffle(aa) {
        for (let i = aa.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [aa[i], aa[j]] = [aa[j], aa[i]];
        }
        return aa;
    }

    const randomValuesCount = length / 2;
    const randomArray = Array.from(new Array(randomValuesCount), (val, index) => index + 1);
    const randomValues = shuffle(randomArray.concat(randomArray));

    return randomValues.map((value, i) => {
        return new PlayFieldItemModel({
            id: i,
            value,
        });
    });
}

export const isIPhoneX = () => {
    const { height: D_HEIGHT, width: D_WIDTH } = Dimensions.get('window');

    return (
        Platform.OS === 'ios' &&
        ((D_HEIGHT === X_HEIGHT && D_WIDTH === X_WIDTH) ||
            (D_HEIGHT === X_WIDTH && D_WIDTH === X_HEIGHT))
    );
};

export const numberWithSpaces = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const getUserPhoto = (userPath) => {
    let imagePath;

    if (!userPath) {
        return null;
    }

    if (userPath.search('http') === -1) {
        switch (process.env.NODE_ENV) {
            case 'development':
                imagePath = `http:${userPath}`;
                break;
            default:
                imagePath = `https:${userPath}`;
        }
    } else {
        imagePath = userPath;
    }

    return imagePath;
};

export const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Use add value with ratio
 * @param value
 * @returns {string}
 */
export const addSizeWithRatio = (value) => {
    const { height } = Dimensions.get('window');
    const parsedValue = parseFloat(value);
    let marginBottom = 0;

    if (parsedValue) {
        if (height >= 810) {
            marginBottom = parsedValue * 2;
        } else if (height >= 730) {
            marginBottom = 1.6 * parsedValue;
        } else if (height >= 660) {
            marginBottom = 1.2 * parsedValue;
        }
    }

    return `${marginBottom}rem`;
};

/**
 * Use increase current value with ratio
 * @param value
 * @param withRem
 * @returns {string}
 */
export const sizeWithRatio = (value, withRem = true) => {
    const { height } = Dimensions.get('window');
    const parsedValue = parseFloat(value);
    let marginBottom = 0;

    if (parsedValue) {
        if (height >= 810) {
            marginBottom = 1.3 * parsedValue;
        } else if (height >= 730) {
            marginBottom = 1.2 * parsedValue;
        } else if (height >= 660) {
            marginBottom = 1.1 * parsedValue;
        } else if (height < 568) {
            // iPhone 4
            marginBottom = 0.8 * parsedValue;
        } else {
            marginBottom = parsedValue;
        }
    }

    if (withRem) {
        return `${marginBottom}rem`;
    } else {
        return marginBottom;
    }
};

export function loadScript(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.addEventListener('load', () => resolve(script), false);
        script.addEventListener('error', () => reject(script), false);
        document.body.appendChild(script);
    });
}
