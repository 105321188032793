import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AuthUI from '../../components/Auth';
import FooterUI from '../../components/Footer';

import {
    GROUP_VK_URL,
    VK_SITE_APP_ID,
    VK_API_VERSION,
    OK_SITE_APP_ID,
    FB_SITE_APP_ID,
    getVKRedirectUrl,
    getOKRedirectUrl,
    getFBRedirectUrl,
    FB_SCOPE,
} from '../../config/social';
import { TYPE_FB, TYPE_NATIVE, goToPage } from '../../store/app';

import {
    IS_PRODUCTION,
    RUSSIAN_LOCALES,
    PRIVACY_POLICY_URL,
    IOS_GAME_LINK,
    ANDROID_GAME_LINK,
} from '../../config';
import { URL_AUTH_PROVIDER, URL_JOIN, URL_PRIVACY } from '../../config/url';
import I18n from '../../i18n';
import { sendJoin } from '../../store/join';

class Auth extends PureComponent {
    static navigationOptions = () => {
        return {
            headerShown: false,
        };
    };

    state = {
        isLoading: false,
    };

    constructor(props) {
        super(props);

        this.handleVKClick = this.handleVKClick.bind(this);
        this.handleOKClick = this.handleOKClick.bind(this);
        this.handleFBClick = this.handleFBClick.bind(this);
        this.handleJoinClick = this.handleJoinClick.bind(this);
        this.handlePrivacyClick = this.handlePrivacyClick.bind(this);
        this.handleAppleClick = this.handleAppleClick.bind(this);
    }

    componentWillUnmount() {
        clearInterval(this.pollTimer);
    }

    render() {
        const isRussianLocaleUser = RUSSIAN_LOCALES.indexOf(I18n.shortLocale) !== -1;
        const notRenderRussianButtons = this.props.appType === TYPE_NATIVE && !isRussianLocaleUser;

        return (
            <AuthUI
                isLoading={this.props.isLoading || this.state.isLoading}
                onVKClick={notRenderRussianButtons ? null : this.handleVKClick}
                onOKClick={notRenderRussianButtons ? null : this.handleOKClick}
                onFBClick={this.handleFBClick}
                onJoinClick={this.handleJoinClick}
                onAppleClick={this.handleAppleClick}
                footer={
                    <FooterUI
                        groupUrl={GROUP_VK_URL}
                        iosLink={IOS_GAME_LINK}
                        androidLink={ANDROID_GAME_LINK}
                        onPrivacyPolicyClick={this.handlePrivacyClick}
                    />
                }
            />
        );
    }

    handleOKClick() {
        const url = `https://connect.ok.ru/oauth/authorize?client_id=${OK_SITE_APP_ID}&scope=&response_type=code&redirect_uri=${getOKRedirectUrl(
            IS_PRODUCTION
        )}&layout=w&state=${Number(new Date())}`;
        this.openUrl(url);
    }

    handleVKClick() {
        const url = `https://oauth.vk.com/authorize?client_id=${VK_SITE_APP_ID}&scope=notify,friends,wall,status&response_type=code&v=${VK_API_VERSION}&redirect_uri=${getVKRedirectUrl(
            IS_PRODUCTION
        )}&display=popup&state=${Number(new Date())}`;
        this.openUrl(url);
    }

    handleFBClick() {
        const url = `https://www.facebook.com/v3.0/dialog/oauth?client_id=${FB_SITE_APP_ID}&redirect_uri=${getFBRedirectUrl(
            IS_PRODUCTION
        )}&state=${Number(new Date())}&scope=${FB_SCOPE}`;
        this.openUrl(url, TYPE_FB);
    }

    handlePrivacyClick() {
        if (this.props.appType === TYPE_NATIVE) {
            this.props.navigation.navigate(URL_PRIVACY, {
                link: PRIVACY_POLICY_URL,
            });
        }
    }

    handleJoinClick() {
        this.props.navigation.navigate(URL_JOIN);
    }

    handleAppleClick(authorizationCode, identityToken, externalId, userInfo) {
        if (this.props.appType === TYPE_NATIVE) {
            const authType = 'apple';
            const authData = {
                authorizationCode,
                identityToken,
                externalId,
                userInfo,
            };

            this.setState({
                isLoading: true,
            });

            this.props.actions
                .sendJoin({
                    authType,
                    authData,
                    onNewUser: () => {
                        this.props.navigation.navigate(URL_JOIN, {
                            authType,
                            authData,
                        });
                    },
                })
                .then(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
        }
    }

    openUrl(url, type) {
        if (this.props.appType === TYPE_NATIVE) {
            this.props.navigation.navigate(URL_AUTH_PROVIDER, {
                url: encodeURI(url),
            });
        } else {
            this.openWindow(url, type);
        }
    }

    openWindow(url, type) {
        let windowSize = ', width=640, height=300';

        if (type === TYPE_FB) {
            windowSize = ', width=640, height=640';
        }

        this.win = window.open(
            url,
            'auth',
            `toolbar=yes, scrollbars=yes, resizable=no, toolbar=no ${windowSize}`
        );

        this.pollTimer = window.setInterval(() => {
            if (this.win && this.win.closed !== false) {
                window.clearInterval(this.pollTimer);
            }
        }, 200);
    }
}

const mapStateToProps = function (state) {
    return {
        isLoading: state.app.isLoading,
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                goToPage,
                sendJoin,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
