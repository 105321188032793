import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Loading from '../../Loading';
import classNames from 'classnames';

const ButtonUI = function (props) {
    const classes = classNames(
        {
            'app-button': true,
            'is-loading': props.isLoading,
        },
        props.className
    );

    return (
        <button className={classes} type="button" onClick={props.onClick}>
            <span className="internal">
                {props.icon ? <img src={props.icon} className="icon" alt="" /> : null}
                <span className="text">{props.text}</span>
            </span>
            <Loading />
        </button>
    );
};

ButtonUI.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default ButtonUI;
