import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import UserModel from '../../../model/user';
import PageCrossUI from '../../UI/PageCross';
import LoadingUI from '../../Loading';
import LevelProgressUI from '../../UI/LevelProgress';
import ProgressItemUI from '../../UI/ProgressItem';
import UserInfoUI from '../../UI/UserInfo';
import GameResultUI from './GameResult';
import NoGamesUI from './NoGames';
import GameResultModel from '../../../model/gameResult';
import ButtonUI from '../../UI/Button';

const renderGames = (entries) => {
    return entries.map((entry) => (
        <GameResultUI
            key={entry.key}
            game={entry.game}
            onFirstPlayerClick={entry.onFirstPlayerClick}
            onSecondPlayerClick={entry.onSecondPlayerClick}
        />
    ));
};

const renderProfileButtons = (props) => {
    return (
        <div className={'profile-buttons'}>
            {props.onInviteFriends ? (
                <ButtonUI
                    text={'Пригласить друзей'}
                    // eslint-disable-next-line react/forbid-component-props
                    className="small-inline invite"
                    //icon={'/images/icons/random-game.svg'}
                    onClick={props.onInviteFriends}
                />
            ) : null}

            {props.onGroupJoin ? (
                <ButtonUI
                    text={'Вступить в клуб'}
                    // eslint-disable-next-line react/forbid-component-props
                    className="small-inline invite"
                    //icon={'/images/icons/random-game.svg'}
                    onClick={props.onGroupJoin}
                />
            ) : null}

            {props.onBuyCoins ? (
                <ButtonUI
                    text={'Добавить монет'}
                    // eslint-disable-next-line react/forbid-component-props
                    className="small-inline coins"
                    //icon={'/images/icons/money.svg'}
                    onClick={props.onBuyCoins}
                />
            ) : null}
        </div>
    );
};

const renderUser = (props) => {
    const { user, lastGames, isOwnProfile } = props;
    const hasActions = props.onInviteFriends || props.onBuyCoins || props.onGroupJoin;

    return (
        <div className="user-container">
            <div className="user-progress-block">
                <UserInfoUI photoUrl={user.photoUrl} level={user.level} />

                <div className="progress-block">
                    <LevelProgressUI
                        levelNeedPoints={user.levelNeedPoints}
                        levelPoints={user.levelPoints}
                        totalPoints={user.totalPoints}
                    />
                    <div className="info">
                        <ProgressItemUI
                            key={'games-count'}
                            icon={'/images/icons/gamepad.svg'}
                            value={user.gamesCount}
                            text={'Игр'}
                        />
                        <ProgressItemUI
                            key={'games-win'}
                            icon={'/images/icons/trophy-white.svg'}
                            value={user.gamesWinCount}
                            text={'Побед'}
                        />
                        <ProgressItemUI
                            key={'coins'}
                            icon={'/images/icons/money.svg'}
                            value={user.money}
                            text={'Монет'}
                        />
                        <ProgressItemUI
                            key={'boost'}
                            icon={'/images/icons/bonus.svg'}
                            value={user.bonuses}
                            text={'Бонусов'}
                        />
                    </div>
                </div>
            </div>

            {isOwnProfile && hasActions ? renderProfileButtons(props) : null}

            <div className="last-games">
                <h2>Недавние игры</h2>
                {lastGames.length === 0 ? <NoGamesUI /> : renderGames(lastGames)}
            </div>
        </div>
    );
};

function PageProfileUI(props) {
    return (
        <div id="page-profile" className="page with-background">
            <PageCrossUI onClick={props.onPageClose} />
            <h1>{props.pageTitle}</h1>
            {props.isLoading ? <LoadingUI loadingText={props.loadingText} /> : renderUser(props)}
        </div>
    );
}

PageProfileUI.propTypes = {
    onPageClose: PropTypes.func.isRequired,
    onInviteFriends: PropTypes.func,
    onBuyCoins: PropTypes.func,
    onGroupJoin: PropTypes.func,
    user: PropTypes.instanceOf(UserModel),
    isOwnProfile: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,
    pageTitle: PropTypes.string,
    lastGames: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            game: PropTypes.instanceOf(GameResultModel).isRequired,
            onFirstPlayerClick: PropTypes.func.isRequired,
            onSecondPlayerClick: PropTypes.func.isRequired,
        })
    ),
};

export default PageProfileUI;
