const SHOW_NOTIFICATION = Symbol('SHOW_NOTIFICATION');

export function showNotification(notification = null) {
    return {
        type: SHOW_NOTIFICATION,
        notification,
    };
}

const SET_NOTIFICATIONS = Symbol('SET_NOTIFICATIONS');

export function setNotifications(notifications = []) {
    return {
        type: SET_NOTIFICATIONS,
        notifications,
    };
}

/**
 *
 * @param {NotificationModel} notification
 * @returns {Function}
 */
export function removeNotification(notification) {
    return function (dispatch, getState) {
        const state = getState();

        const newNotifications = state.notification.notifications.filter((item) => {
            return item.id !== notification.id;
        });

        dispatch(setNotifications(newNotifications));
    };
}

const initialState = {
    notifications: [],
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SHOW_NOTIFICATION] = function () {
        const newNotifications = state.notifications.slice();
        newNotifications.push(action.notification);

        return {
            ...state,
            notifications: newNotifications,
        };
    };

    exec[SET_NOTIFICATIONS] = function () {
        return {
            ...state,
            notifications: action.notifications,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
