export const TYPE_DEFAULT = 'Default';
export const TYPE_ERROR = 'Error';

class NotificationModel {
    id = null;

    title = null;

    description = null;

    type = TYPE_DEFAULT;

    timeout = 1500;

    constructor(data) {
        this.id = Math.random();
        this.title = data.title;
        this.description = data.description;

        if (data.type) {
            this.type = data.type;
        }

        if (data.timeout) {
            this.timeout = data.timeout;
        }
    }
}

export default NotificationModel;
