import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import RatingUserModel from '../../../../model/ratingUser';
import UserInfoUI from '../../../UI/UserInfo';

function RatingUserUI(props) {
    /**
     *
     * @type {RatingUserModel}
     */
    const ratingUser = props.user;

    return (
        <div className="rating-user hover-item" onClick={props.onClick}>
            <div className="rating-position">
                <span>{ratingUser.position}</span>
            </div>

            <UserInfoUI
                photoUrl={ratingUser.user.photoUrl}
                level={ratingUser.user.level}
                type={UserInfoUI.TYPE.MEDIUM}
            />

            <div className="user">
                <div className="name">
                    {ratingUser.user.firstName} {ratingUser.user.lastName}
                </div>
                <div className="games-info">
                    <div className="info-item score">
                        <img className="icon" src={'/images/icons/star.svg'} alt={''} />
                        <span>{ratingUser.points}</span>
                    </div>
                    <div className="info-item games-win">
                        <img className="icon" src={'/images/icons/trophy-white.svg'} alt={''} />
                        <span>{ratingUser.gamesWin}</span>
                    </div>
                    <div className="info-item games-played">
                        <img className="icon" src={'/images/icons/gamepad.svg'} alt={''} />
                        <span>{ratingUser.gamesCount}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

RatingUserUI.propTypes = {
    user: PropTypes.instanceOf(RatingUserModel),
    onClick: PropTypes.func,
};

export default RatingUserUI;
