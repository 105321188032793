import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import PageGameFriendIntroUI, {
    STEP_CONNECT,
    STEP_INTO,
    STEP_NEW_GAME,
} from '../../../../components/Pages/Game/Friend';

import { URL_HOME } from '../../../../config/url';
import { showInvite, TYPE_FB, TYPE_VK, TYPE_VK_APP } from '../../../../store/app';
import { GAME_TYPE_FRIEND } from '../../../../config';

import { checkIfFriendGameExits } from '../../../../store/game';

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

class FriendGame extends PureComponent {
    constructor(props) {
        super(props);

        this.handlePageClose = this.handlePageClose.bind(this);
        this.handleInviteFriend = this.handleInviteFriend.bind(this);
        this.handleNewGameStart = this.handleNewGameStart.bind(this);
        this.handleGameConnect = this.handleGameConnect.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleBackToMenu = this.handleBackToMenu.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);

        this.state = {
            gameCode: '',
            friendGameStep: STEP_INTO,
            isLoading: false,
            errorMessage: '',
            isWaitingFriend: false,
        };
    }

    render() {
        return (
            <PageGameFriendIntroUI
                step={this.state.friendGameStep}
                gameCode={this.state.gameCode}
                isLoading={this.state.isLoading}
                onNewGameStart={this.handleNewGameStart}
                onGameConnect={this.handleGameConnect}
                onStart={this.handleStart}
                onBackToMenu={this.handleBackToMenu}
                onPageClose={this.handlePageClose}
                onFriendInvite={
                    [TYPE_VK, TYPE_VK_APP, TYPE_FB].indexOf(this.props.appType) !== -1
                        ? this.handleInviteFriend
                        : null
                }
                onCodeChange={this.handleCodeChange}
                errorMessage={this.state.errorMessage}
                isWaitingFriend={this.state.isWaitingFriend}
            />
        );
    }

    handlePageClose() {
        this.props.actions.toUrl(URL_HOME);
    }

    handleInviteFriend() {
        this.props.actions.showInvite(this.props.appType);
    }

    handleNewGameStart() {
        this.setState({
            friendGameStep: STEP_NEW_GAME,
            gameCode: getRandomInt(1000, 9999),
        });
    }

    handleGameConnect() {
        this.setState({
            friendGameStep: STEP_CONNECT,
        });
    }

    handleStart() {
        if (!this.state.isLoading) {
            if (!this.state.gameCode) {
                this.setState({
                    errorMessage: 'Введите код игры',
                });
            } else {
                this.setState({
                    isLoading: true,
                    errorMessage: '',
                });

                if (this.state.friendGameStep === STEP_CONNECT) {
                    // Check game for existing before start
                    this.props.actions
                        .checkIfFriendGameExits(this.state.gameCode)
                        .then((game) => {
                            this.props.onStart(GAME_TYPE_FRIEND, this.state.gameCode, game.id);
                        })
                        .catch(() => {
                            this.setState({
                                isLoading: false,
                                errorMessage: 'Такая игра не найдена',
                            });
                        });
                } else {
                    this.setState({ isWaitingFriend: true });
                    this.props.onStart(GAME_TYPE_FRIEND, this.state.gameCode);
                }
            }
        }
    }

    handleBackToMenu() {
        if (!this.state.isLoading) {
            this.setState({
                friendGameStep: STEP_INTO,
                gameCode: '',
                errorMessage: '',
            });
        }
    }

    handleCodeChange(event) {
        this.setState({
            gameCode: event.target.value,
        });
    }
}

FriendGame.propTypes = {
    onStart: PropTypes.func.isRequired,
    navigation: PropTypes.object,
};

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                toUrl: push,
                showInvite,
                checkIfFriendGameExits,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FriendGame);
