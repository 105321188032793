import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import TopUserUI from './TopUser';
import UserModel from '../../model/user';

function TopUsersUI(props) {
    return (
        <div id="top-users">
            <div className="header">
                <img src={'/images/icons/trophy.svg'} alt="" />
                <span>Топ сегодня</span>
            </div>
            <div className="users">
                {props.users.map((entry) => {
                    return (
                        <TopUserUI user={entry.user} key={entry.user.id} onClick={entry.onClick} />
                    );
                })}
            </div>
        </div>
    );
}

TopUsersUI.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            user: PropTypes.instanceOf(UserModel),
            onClick: PropTypes.func,
        })
    ),
};

export default TopUsersUI;
