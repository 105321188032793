import React from 'react';

function NoGamesUI() {
    return (
        <div className="no-games">
            <img className="icon" src={'/images/icons/sad.svg'} alt={''} />
            <div className="text">Игр еще не было</div>
        </div>
    );
}

export default NoGamesUI;
