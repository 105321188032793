import UserModel from '../../model/user';
import { fetchLastGames } from '../user';
import axios from 'axios';
import { SERVER_API } from '../../config';

const SET_USER_INFO = Symbol('SET_USER_INFO');

const CancelToken = axios.CancelToken;
let tokenProfileCancel;

export function setUserInfo(info) {
    return {
        type: SET_USER_INFO,
        info,
    };
}

export function setUser(data) {
    return function (dispatch) {
        dispatch(setUserInfo(new UserModel(data)));
    };
}

export function uploadPhoto(base64Photo, session = null) {
    return function (dispatch, getState) {
        return axios
            .post(`${SERVER_API}/user/photo`, {
                base64Photo,
                session: session || getState().app.session,
            })
            .then(function (response) {
                if (response.data.user) {
                    return response.data.user;
                }
            });
    };
}

export function fetchProfile() {
    return function (dispatch, getState) {
        const userId = getState().profile.info.id;

        if (tokenProfileCancel) {
            tokenProfileCancel();
            tokenProfileCancel = null;
        }

        return axios
            .get(`${SERVER_API}/user`, {
                cancelToken: new CancelToken((token) => {
                    tokenProfileCancel = token;
                }),
                params: {
                    userId,
                },
            })
            .then(function (response) {
                tokenProfileCancel = null;
                if (response.data.user) {
                    dispatch(setUser(response.data.user));
                }
            });
    };
}

export function fetchLastProfileGames() {
    return function (dispatch, getState) {
        const userId = getState().profile.info.id;

        if (userId) {
            fetchLastGames(userId).then((games) => {
                dispatch(setProfileLastGames(games));
                dispatch(setIsLoading(false));
            });
        }
    };
}

export function sendSawAdvForMoney() {
    return function (dispatch, getState) {
        return axios
            .post(
                `${SERVER_API}/user/adv-money`,
                {
                    session: getState().app.session,
                },
                { withCredentials: true }
            )
            .then((response) => {
                dispatch(setUser(response.data.user));
            })
            .catch((error) => {
                if (error.response.data.error) {
                    throw new Error(error.response.data.error);
                }
            });
    };
}

const SET_LAST_GAMES = Symbol('SET_LAST_GAMES');

export function setProfileLastGames(lastGames) {
    return {
        type: SET_LAST_GAMES,
        lastGames,
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');

function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

const initialState = {
    info: null,
    lastGames: null,
    isLoading: false,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_USER_INFO] = function () {
        return {
            ...state,
            info: action.info,
        };
    };

    exec[SET_LAST_GAMES] = function () {
        return {
            ...state,
            lastGames: action.lastGames,
        };
    };

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
