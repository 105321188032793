import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import PageGameLoadingUI from '../../../../components/Pages/Game/Loading';
import ButtonUI from '../../../../components/UI/Button';
import NoPlayersUI from '../../../../components/Pages/Game/NoPlayers';

import { GAME_TYPE_RANDOM, GAME_TYPE_SINGLE } from '../../../../config';

import { URL_GAME, URL_HOME } from '../../../../config/url';
import I18n from '../../../../i18n';
import { TYPE_NATIVE } from '../../../../store/app';
import Style, { CANCEL_BUTTON_GRADIENT } from '../../../../style';

const LOADING_TIMER = 10000;

class RandomGame extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            noGamers: false,
        };

        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleSingleGame = this.handleSingleGame.bind(this);
    }

    componentDidMount() {
        this.props.onStart(GAME_TYPE_RANDOM);

        this.findTimer = setTimeout(() => {
            this.setState({
                noGamers: true,
            });
        }, LOADING_TIMER);
    }

    componentWillUnmount() {
        clearInterval(this.findTimer);
    }

    render() {
        return (
            <PageGameLoadingUI
                titleText={I18n.t('opponentSearch')}
                descriptionText={I18n.t('waitingSecondPlayer')}
                buttons={[
                    <ButtonUI
                        key={'cancel'}
                        text={I18n.t('cancelIt')}
                        className={'small-inline'}
                        onClick={this.handleCancelClick}
                        colors={CANCEL_BUTTON_GRADIENT}
                        containerStyle={Style.Button100}
                    />,
                ]}
                additional={this.renderAdditional()}
            />
        );
    }

    renderAdditional() {
        return this.state.noGamers ? (
            <NoPlayersUI onButtonClick={this.handleSingleGame} text={I18n.t('noActiveGames')} />
        ) : null;
    }

    handleCancelClick() {
        if (this.props.appType === TYPE_NATIVE) {
            this.props.navigation.navigate(URL_HOME);
        } else {
            this.props.actions.toUrl(URL_HOME);
        }
    }

    handleSingleGame(event) {
        event.preventDefault();
        this.props.actions.toUrl(`${URL_GAME}/${GAME_TYPE_SINGLE}`);
    }
}

RandomGame.propTypes = {
    onStart: PropTypes.func.isRequired,
    navigation: PropTypes.object,
};

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                toUrl: push,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RandomGame);
