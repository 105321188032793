import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import MultiplayerGamePanelUI from '../../../../../components/Pages/Game/MultiplayerGamePanel';
import { EVENT_SERVER_GAME_TIMER } from '../../../../../config/socket';
import { setTimerValue } from '../../../../../store/game';
import PlayUser from '../../../../../model/playUser';

class MultiplayerGamePanel extends PureComponent {
    constructor(props) {
        super(props);

        this.onTimer = this.onTimer.bind(this);
    }

    componentDidMount() {
        this.props.socket.on(EVENT_SERVER_GAME_TIMER, this.onTimer);
    }

    componentWillUnmount() {
        this.props.socket.off(EVENT_SERVER_GAME_TIMER, this.onTimer);
    }

    render() {
        const {playerUserStat, opponentUserStat} = this.props;

        return (
            <MultiplayerGamePanelUI
                seconds={this.props.timer}
                roundValue={this.props.roundValue}
                playerUserStat={playerUserStat}
                opponentUserStat={opponentUserStat}
                activeUserId={this.props.activeUserId}
            />
        );
    }

    /**
     *
     * @param response
     * @param response.timer
     */
    onTimer(response) {
        this.props.actions.setTimerValue(response.timer);
    }
}

MultiplayerGamePanel.propTypes = {
    playerUserStat: PropTypes.instanceOf(PlayUser).isRequired,
    opponentUserStat: PropTypes.instanceOf(PlayUser).isRequired,
    roundValue: PropTypes.number.isRequired,
    activeUserId: PropTypes.number.isRequired
};

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        timer: state.game.timer,
        socket: state.app.socket
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators({
            setTimerValue
        }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiplayerGamePanel);
