import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const ButtonsGroupUI = function (props) {
    return <div className={`buttons-group ${props.className}`}>{props.buttons}</div>;
};

ButtonsGroupUI.propTypes = {
    buttons: PropTypes.array.isRequired,
    className: PropTypes.string,
};

export default ButtonsGroupUI;
