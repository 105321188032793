import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PageHelpUI from '../../../components/Pages/Help';
import { URL_HOME } from '../../../config/url';
import { push } from 'connected-react-router';
import { fetchPage } from '../../../store/page';

const HELP_PAGE_ID = 'help';

class PageHelp extends PureComponent {
    constructor(props) {
        super(props);

        this.handlePageClose = this.handlePageClose.bind(this);
    }

    componentDidMount() {
        if (!this.props.text) {
            this.props.actions.fetchPage(HELP_PAGE_ID);
        }
    }

    render() {
        return (
            <PageHelpUI
                onPageClose={this.handlePageClose}
                isLoading={this.props.isLoading}
                text={this.props.text}
                loadingText={'Загрузка страницы'}
            />
        );
    }

    handlePageClose() {
        this.props.actions.toUrl(URL_HOME);
    }
}

const mapStateToProps = function (state) {
    const text = state.page.pages[HELP_PAGE_ID] ? state.page.pages[HELP_PAGE_ID] : '';

    return {
        text,
        isLoading: state.page.isLoading,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                toUrl: push,
                fetchPage,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHelp);
