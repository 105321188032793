import React from 'react';
import './style.scss';

export default function () {
    return (
        <div id="app-loading">
            <div className="loading-state">
                <div className="logo"></div>
                <div className="app-loading la-ball-grid-pulse la-2x">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div>Идет загрузка...</div>
            </div>
        </div>
    );
}
