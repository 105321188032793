import axios from 'axios';
import { SERVER_API } from '../../config';
import RatingUserModel from '../../model/ratingUser';

const CancelToken = axios.CancelToken;
let ratingUsersCancel;

const SET_RATING_USERS = Symbol('SET_RATING_USERS');

function setRatingUsers(users) {
    return {
        type: SET_RATING_USERS,
        users,
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');

function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

export function fetchRatingUsers(filterValue, page = 0, limit = 100) {
    return function (dispatch) {
        if (page === 0) {
            dispatch(setRatingUsers(null));
        }

        if (ratingUsersCancel) {
            ratingUsersCancel();
            ratingUsersCancel = null;
        }

        dispatch(setIsLoading(true));

        return axios
            .get(`${SERVER_API}/top/${filterValue}`, {
                cancelToken: new CancelToken((token) => {
                    ratingUsersCancel = token;
                }),
                params: {
                    offset: page * limit,
                    limit,
                },
            })
            .then(function (response) {
                ratingUsersCancel = null;

                if (response.data.users) {
                    const users = response.data.users.map((user) => {
                        return new RatingUserModel(user);
                    });

                    dispatch(setRatingUsers(users));
                    dispatch(setIsLoading(false));
                }
            })
            .catch((thrown) => {
                if (!axios.isCancel(thrown)) {
                    throw new Error(thrown);
                }
            });
    };
}

const initialState = {
    users: null,
    request: null,
    isLoading: false,
    currentPage: 0,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_RATING_USERS] = function () {
        return {
            ...state,
            users: action.users,
        };
    };

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
