import EStyleSheet from 'react-native-extended-stylesheet';

export const MAIN_BG_COLOR = '#fd4b6d';
export const TINT_COLOR = '#fd4b6d';
export const SELECTED_ITEM_COLOR = '#b7384d';
export const ICON_FILL_COLOR = '#FFF';
export const HEADER_ICON_SIZE = 25;
export const HEADERS_FONT_SIZE = '0.9rem';
export const RANDOM_GAME_COLOR = '#69aa2f';
export const RANDOM_GAME_COLOR_GRADIENT = '#578d25';
export const FRIEND_GAME_COLOR = '#3f84d1';
export const SINGLE_GAME_COLOR = '#b7384d';
export const SINGLE_GAME_COLOR_GRADIENT = '#c21906';
export const ACTIVITY_INDICATOR_COLOR = '#ffffff';
export const IPHONE8_HEIGHT = 736;
export const COLOR_WIN = '#facf2e';

export const CANCEL_BUTTON_GRADIENT = ['#fc6572', '#ff9747'];

const styles = {
    text: {
        color: '#fff',
        fontSize: 16,
        marginTop: 3,
        marginBottom: 3,
    },

    buttonText: {
        color: '#fff',
        fontSize: '0.9rem',
    },

    header1: {
        color: '#fff',
        fontSize: '2rem',
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 10,
    },

    vkButton: {
        backgroundColor: '#45668e',
    },

    fbButton: {
        backgroundColor: '#3b5998',
    },

    okButton: {
        backgroundColor: '#ed812b',
    },

    shareButton: {
        backgroundColor: '#444f8e',
    },

    inviteButton: {
        backgroundColor: '#8e3873',
    },

    moneyButton: {
        backgroundColor: '#31afa3',
    },

    joinButton: {
        backgroundColor: '#439854',
    },

    singleGameColor: {
        backgroundColor: SINGLE_GAME_COLOR,
    },

    randomGameColor: {
        backgroundColor: RANDOM_GAME_COLOR,
    },

    headerBar: {
        color: '#fff',
        fontSize: 21,
    },

    badgeStyle: {
        position: 'absolute',
        left: 30,
        bottom: 0,
        backgroundColor: 'rgba(199,77,24,1)',
        borderColor: 'rgba(255,255,255,0.35)',
        borderWidth: 2,
        width: '2.6rem',
        height: '2.6rem',
        borderRadius: 50,
        paddingTop: '0.1rem',
        paddingLeft: '0.1rem',
        paddingRight: '0.1rem',
        paddingBottom: '0.1rem',
    },

    badgeTextStyle: {
        fontSize: '1.2rem',
        fontWeight: '600',
        color: '#fff',
    },

    dialog: {
        display: 'flex',
        backgroundColor: '#fff',
        padding: '1rem',
        borderRadius: 5,
        width: '100%',
    },

    h2: {
        color: SELECTED_ITEM_COLOR,
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        marginBottom: '0.5rem',
    },

    Button100: { display: 'flex', width: '100%' },
};

styles.badgeStyleSmall = Object.assign({}, styles.badgeStyle, {
    left: 17,
    width: 30,
    height: 30,
});

styles.badgeTextStyleSmall = Object.assign({}, styles.badgeTextStyle, {
    fontSize: '0.5rem',
});

styles.textSmall = Object.assign({}, styles.text, { fontSize: 14 });
styles.textSmallCenter = Object.assign({}, styles.textSmall, { textAlign: 'center' });

export const textShadow = {
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 2,
};

export const HEADER_OPTIONS = {
    navigationOptions: {
        headerStyle: {
            backgroundColor: SELECTED_ITEM_COLOR,
        },
        headerTitleStyle: {
            color: '#FFFFFF',
            fontWeight: '300',
            fontSize: 18,
            textTransform: 'uppercase',
        },
        headerBackTitleStyle: {
            color: '#FFFFFF',
        },
        headerTintColor: '#FFF',
    },
};

export default EStyleSheet.create(styles);
