import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import PageGameLoadingUI from '../../../../components/Pages/Game/Loading';
import I18n from '../../../../i18n';

import { GAME_TYPE_SINGLE } from '../../../../config';

class SingleGame extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.onStart(GAME_TYPE_SINGLE);
        }, 1000);
    }

    render() {
        return <PageGameLoadingUI titleText={I18n.t('loadingGame')} />;
    }
}

SingleGame.propTypes = {
    onStart: PropTypes.func.isRequired,
    navigation: PropTypes.object,
};

const mapStateToProps = function () {
    return {};
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators({}, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleGame);
