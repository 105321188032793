import moment from 'moment';

class UserModel {
    id = null;

    firstName = null;

    lastName = null;

    photoUrl = null;

    level = null;

    levelPoints = null;

    levelNeedPoints = null;

    totalPoints = 0;

    gamesCount = 0;

    gamesWinCount = 0;

    money = 0;

    ratingPosition = true;

    source = null;

    bonuses = 0;

    boost_active_before_at = null;

    constructor(userData) {
        const boostDate = moment(userData.boost_active_before_at);
        const now = moment.utc();

        this.id = userData.id;
        this.firstName = userData.first_name;
        this.lastName = userData.last_name;
        this.photoUrl = userData.photo;
        this.level = userData.level || 0;
        this.levelPoints = userData.current_level_points || 0;
        this.levelNeedPoints = userData.points_to_next_level || 0;
        this.money = userData.money || 0;
        this.totalPoints = userData.total_points || 0;
        this.ratingPosition = userData.rating_position;
        this.gamesCount = userData.games_count || 0;
        this.gamesWinCount = userData.games_win || 0;
        this.source = userData.source;
        this.bonuses = userData.bonuses || 0;
        this.hasBoost = boostDate > now;
        this.hasBonuses = this.bonuses > 0;
        this.leavesСount = userData.leaves_count;
        this.city = userData.city;
        this.externalType = userData.external_type;
        this.externalId = userData.external_id;
        this.type = userData.type;
        this.advBlockingDate = userData.adv_view_blocked_date;
    }
}

export default UserModel;
