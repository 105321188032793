import { YA_METRIKA_ID } from '../../config';

export const EVENT_START_SINGLE = 'startGameSingle';
export const EVENT_START_GAME = 'startGame';
export const EVENT_START_FRIEND_GAME = 'startFriendGame';
export const EVENT_START_CLICK_INVITE = 'clickInvite';
export const EVENT_START_SENT_INVITE_SUCCESS = 'sendInviteSuccess';
export const EVENT_ADV_FOR_MONEY = 'seeAdvForMoney';
export const EVENT_SEE_ADV_PRELOAD = 'seeAdvPreload';
export const EVENT_SEE_ADV_INTERSTITIAL = 'seeAdvInterstitial';
export const EVENT_MONEY_TOOLTIP_CLICK = 'clickMoneyTooltip';

export function sendReachGoal(eventName, params = {}) {
    return function (dispatch, getState) {
        if (typeof window !== 'undefined' && window.ym) {
            const appType = getState().app.appType;
            const mergedParams = {
                ...{
                    appType,
                },
                ...params,
            };
            window.ym(YA_METRIKA_ID, 'reachGoal', eventName, mergedParams);
        }
    };
}
