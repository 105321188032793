import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import moment from 'moment';

import GameResultModel from '../../../../model/gameResult';
import UserInfoUI from '../../../UI/UserInfo';

import { GAME_TYPE_FRIEND, GAME_TYPE_RANDOM, GAME_TYPE_SINGLE } from '../../../../config';

function GameResultUI(props) {
    const game = props.game;
    const firstUser = game.firstPlayer;
    const secondUser = game.secondPlayer;

    const renderPoints = (points) => {
        return (
            <div className="points">
                <img src={'/images/icons/star.svg'} alt={''} className="icon" />
                <span className="points-text">{points} очков</span>
            </div>
        );
    };

    const getIconType = (gameType) => {
        switch (gameType) {
            case GAME_TYPE_SINGLE:
                return (
                    <React.Fragment>
                        <UserInfoUI photoUrl={firstUser.photoUrl} type={UserInfoUI.TYPE.MEDIUM} />
                        <div className={'game-type-wrapper small single'} />
                    </React.Fragment>
                );
            case GAME_TYPE_RANDOM:
                return (
                    <React.Fragment>
                        <UserInfoUI
                            photoUrl={secondUser.photoUrl}
                            type={UserInfoUI.TYPE.MEDIUM}
                            onClick={props.onSecondPlayerClick}
                        />
                        <div className={'game-type-wrapper small random'} />
                    </React.Fragment>
                );
            case GAME_TYPE_FRIEND:
                return (
                    <React.Fragment>
                        <UserInfoUI
                            photoUrl={secondUser.photoUrl}
                            type={UserInfoUI.TYPE.MEDIUM}
                            onClick={props.onSecondPlayerClick}
                        />
                        <div className={'game-type-wrapper small friend'} />
                    </React.Fragment>
                );
        }
    };

    return (
        <div className="game-result">
            <div className={'game-date'} title={moment(game.date).calendar()}>
                <span className={'day'}>{moment(game.date).format('DD')}</span>
                <span className={'month'}>{moment(game.date).format('MMM')}</span>
            </div>
            <div className="game-type">{getIconType(game.type)}</div>
            <div className={'game-info'}>
                {renderPoints(firstUser.points)}

                <div className="datetime">
                    <span className={'attempts'}>
                        <img className="icon" src="/images/icons/dice.svg" alt="Ходов" />{' '}
                        {firstUser.attempts} ходов
                    </span>
                    <span className={'attempts-right'}>
                        <img className="icon" src="/images/icons/checked.svg" alt="Угадано" />{' '}
                        {firstUser.rightAttempts} угадано
                    </span>
                    <br />
                </div>
            </div>
        </div>
    );
}

GameResultUI.propTypes = {
    game: PropTypes.instanceOf(GameResultModel),
    onFirstPlayerClick: PropTypes.func,
    onSecondPlayerClick: PropTypes.func,
};

export default GameResultUI;
