import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import io from 'socket.io-client';
import PropTypes from 'prop-types';

import { SERVER_SOCKET_HOST, SERVER_SOCKET_IS_SECURE } from '../../config';
import { setSocket, setIsReady } from '../../store/app';
import { EVENT_SERVER_READY } from '../../config/socket';

const MAX_CONNECTION_ATTEMPTS = 10;
const RECONNECTION_DELAY = 10000;

class Socket extends PureComponent {
    constructor() {
        super();

        this.connectionErrorCount = 0;
        this.socket = null;
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive && !prevProps.session && this.props.session) {
            this.connect();
        }

        if (this.props.session && !prevProps.isActive && this.props.isActive) {
            this.connect();
        }

        if (this.socket && prevProps.isActive && !this.props.isActive) {
            this.stopConnection();
        }

        if (this.socket && prevProps.session && !this.props.session) {
            this.stopConnection();
        }
    }

    stopConnection() {
        console.log('Socket stop connection');
        this.socket.disconnect();
        this.socket = null;
    }

    render() {
        return null;
    }

    connect() {
        if (this.socket) {
            return false;
        }

        if (!this.props.session) {
            return false;
        }

        const queryParams = {
            session: this.props.session,
        };

        this.socket = io(SERVER_SOCKET_HOST, {
            transports: ['websocket'],
            upgrade: false,
            timeout: 5000,
            autoConnect: true,
            secure: SERVER_SOCKET_IS_SECURE,
            query: queryParams,
        });

        this.socket.on('connect', () => {
            console.log('Socket connected');

            this.connectionErrorCount = 0;
            this.props.actions.setSocket(this.socket);

            if (this.props.onConnectionStatusChange) {
                this.props.onConnectionStatusChange(true);
            }
        });

        this.socket.on('error', () => {
            console.log('Socket error');

            if (this.props.onConnectionStatusChange) {
                this.props.onConnectionStatusChange(false);
            }
        });

        this.socket.on('connect_error', () => {
            console.log('Socket connect error');

            if (this.props.onConnectionStatusChange) {
                this.props.onConnectionStatusChange(false);
            }
        });

        this.socket.on('reconnect', () => {
            console.log('Socket reconnected');
            this.connectionErrorCount = 0;
        });

        this.socket.on('reconnect_attempt', (attemptNumber) => {
            console.log(`Socket reconnect attempt ${attemptNumber}`);
            this.connectionErrorCount = attemptNumber;

            if (this.connectionErrorCount === MAX_CONNECTION_ATTEMPTS) {
                console.log(
                    `Max connection attempts reached ${MAX_CONNECTION_ATTEMPTS}. Add delay ${
                        RECONNECTION_DELAY / 1000
                    }s`
                );
                this.socket.io.opts.reconnectionDelay = RECONNECTION_DELAY;
            }
        });

        this.socket.on(EVENT_SERVER_READY, () => {
            this.props.actions.setIsReady(true);
        });

        return this.socket;
    }
}

Socket.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onConnectionStatusChange: PropTypes.func,
};

const mapStateToProps = function (state) {
    return {
        session: state.app.session,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                setSocket,
                setIsReady,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Socket);
