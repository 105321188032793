import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import PageCrossUI from '../../UI/PageCross';
import ButtonsGroupUI from '../../UI/ButtonsGroup';
import Loading from '../../Loading';
import ButtonGroupItemUI from '../../UI/ButtonsGroup/Item';
import RatingUserUI from './User';

const renderUsers = (users) => {
    return (
        <div className="users-list">
            {users.map((user) => {
                return <RatingUserUI key={user.key} user={user.model} onClick={user.onClick} />;
            })}
        </div>
    );
};

const renderButtons = (buttons) => {
    return (
        <ButtonsGroupUI
            buttons={buttons.map((button) => {
                return (
                    <ButtonGroupItemUI
                        key={button.key}
                        text={button.text}
                        isActive={button.isActive}
                        onClick={button.onClick}
                    />
                );
            })}
        />
    );
};

function PageRatingUI(props) {
    return (
        <div id="page-rating" className="page with-background">
            <PageCrossUI onClick={props.onPageClose} />
            <h1>Рейтинг игроков</h1>

            <div className="filter">{renderButtons(props.buttons)}</div>
            {props.isLoading ? (
                <Loading loadingText={props.loadingText} />
            ) : (
                renderUsers(props.users)
            )}
        </div>
    );
}

PageRatingUI.propTypes = {
    onPageClose: PropTypes.func.isRequired,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            isActive: PropTypes.bool.isRequired,
            onClick: PropTypes.func.isRequired,
        })
    ).isRequired,
    isLoading: PropTypes.bool,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            model: PropTypes.object.isRequired,
            key: PropTypes.number.isRequired,
            onClick: PropTypes.func.isRequired,
        })
    ),
    loadingText: PropTypes.string,
};

export default PageRatingUI;
