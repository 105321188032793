import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goToPage, TYPE_NATIVE } from '../../../store/app';

import PageRatingUI from '../../../components/Pages/Rating';
import { URL_HOME, URL_PROFILE, URL_RATING, URL_RATING_USER } from '../../../config/url';
import { fetchRatingUsers } from '../../../store/rating';
import i18n from '../../../i18n';

import { FILTER_DAY, FILTER_MONTH, FILTER_TOTAL, FILTER_WEEK } from '../../../config';

class PageRating extends PureComponent {
    static navigationOptions = () => {
        return {
            title: i18n.t('pages.rating.title'),
        };
    };

    constructor(props) {
        super(props);

        this.handlePageClose = this.handlePageClose.bind(this);

        this.state = {
            filterValue: this.getFilterValue(this.getQueryFilterValue()),
        };
    }

    componentDidUpdate(prevProps) {
        const newFilter = this.getQueryFilterValue();

        if (
            prevProps.match &&
            prevProps.match.params &&
            prevProps.match.params.filter !== newFilter
        ) {
            this.updateFilterState(newFilter);
        }
    }

    componentDidMount() {
        this.props.actions.fetchRatingUsers(this.state.filterValue);
    }

    render() {
        return (
            <PageRatingUI
                onPageClose={this.handlePageClose}
                buttons={this.getButtons()}
                isLoading={this.props.users === null}
                users={this.getUsers(this.props.users)}
                loadingText={'Загрузка игроков'}
            />
        );
    }

    getQueryFilterValue() {
        let queryFilterParam = null;

        if (this.props.match && this.props.match.params) {
            queryFilterParam = this.props.match.params.filter;
        }

        return queryFilterParam;
    }

    updateFilterState(filterValue) {
        const newFilterValue = this.getFilterValue(filterValue);

        this.setState({
            filterValue: newFilterValue,
        });
    }

    getFilterValue(filterValue) {
        const availableValues = [FILTER_DAY, FILTER_MONTH, FILTER_TOTAL, FILTER_WEEK];

        if (availableValues.indexOf(filterValue) === -1) {
            // eslint-disable-next-line no-param-reassign
            filterValue = FILTER_DAY;
        }

        return filterValue;
    }

    getButtons() {
        const { filterValue } = this.state;

        return [
            {
                key: FILTER_DAY,
                text: i18n.t('day'),
                isActive: filterValue === FILTER_DAY,
                onClick: this.handleChangeFilter.bind(this, FILTER_DAY),
            },
            {
                key: FILTER_WEEK,
                text: i18n.t('week'),
                isActive: filterValue === FILTER_WEEK,
                onClick: this.handleChangeFilter.bind(this, FILTER_WEEK),
            },
            {
                key: FILTER_MONTH,
                text: i18n.t('month'),
                isActive: filterValue === FILTER_MONTH,
                onClick: this.handleChangeFilter.bind(this, FILTER_MONTH),
            },
            {
                key: FILTER_TOTAL,
                text: i18n.t('total'),
                isActive: filterValue === FILTER_TOTAL,
                onClick: this.handleChangeFilter.bind(this, FILTER_TOTAL),
            },
        ];
    }

    getUsers(users) {
        if (!Array.isArray(users) || users.length === 0) {
            return [];
        }

        return users.map((ratingModel) => ({
            key: ratingModel.user.id,
            model: ratingModel,
            onClick: this.handleOpenProfile.bind(this, ratingModel.user),
        }));
    }

    handlePageClose() {
        this.props.actions.goToPage(URL_HOME);
    }

    handleChangeFilter(filterValue) {
        if (this.props.appType !== TYPE_NATIVE) {
            // Only web
            this.props.actions.goToPage(`${URL_RATING}/${filterValue}`);
        }

        this.props.actions.fetchRatingUsers(filterValue);

        this.setState({
            filterValue,
        });
    }

    handleOpenProfile(user) {
        if (this.props.appType === TYPE_NATIVE) {
            this.props.navigation.navigate(URL_RATING_USER, { user });
        } else {
            this.props.actions.goToPage(`${URL_PROFILE}/${user.id}`, { user, hasBack: true });
        }
    }
}

const mapStateToProps = function (state) {
    return {
        users: state.rating.users,
        currentPage: state.rating.currentPage,
        isLoading: state.rating.isLoading,
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                goToPage,
                fetchRatingUsers,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageRating);
