import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import I18n from '../../i18n';

const FooterUI = function (props) {
    return (
        <div id="footer-block">
            <div className="menu">
                {props.groupUrl ? (
                    <a href={props.groupUrl} target="_blank" rel="noopener noreferrer">
                        <img className="icon" alt="" src={'/images/icons/public.svg'} /> Группа игры
                    </a>
                ) : null}
                {props.iosLink ? (
                    <a href={props.iosLink} target={'_blank'} rel="noopener noreferrer">
                        <img className="icon apple" alt="" src={'/images/icons/apple.svg'} /> Версия
                        для iPhone
                    </a>
                ) : null}
                {props.androidLink ? (
                    <a href={props.androidLink} target={'_blank'} rel="noopener noreferrer">
                        <img className="icon" alt="" src={'/images/icons/android.svg'} /> Версия для
                        Android
                    </a>
                ) : null}
            </div>
            <div className="copyright">
                {I18n.t('gameName')} © {new Date().getFullYear()}, witgames.info
            </div>
        </div>
    );
};

FooterUI.propTypes = {
    groupUrl: PropTypes.string.isRequired,
    iosLink: PropTypes.string,
    androidLink: PropTypes.string,
};

export default FooterUI;
