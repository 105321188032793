import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { push } from 'connected-react-router';

class PageShare extends PureComponent {
    render() {
        return null;
    }
}

const mapStateToProps = function () {
    return {};
};

const mapDispatchToProps = function (dispatch) {
    return {
        toUrl: (url) => {
            dispatch(push(url));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageShare);
