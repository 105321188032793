import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import PageShopUI from '../../../components/Pages/Shop';
import { URL_HOME } from '../../../config/url';

import { fetchShop, buyItem } from '../../../store/shop';
import i18n from '../../../i18n';

class PageShop extends PureComponent {
    static navigationOptions = () => {
        return {
            title: i18n.t('pages.shop.title'),
        };
    };

    constructor(props) {
        super(props);

        this.handlePageClose = this.handlePageClose.bind(this);

        this.state = {
            activeElement: null,
        };
    }

    componentDidMount() {
        this.props.actions.fetchShop();
    }

    render() {
        return (
            <PageShopUI
                onPageClose={this.handlePageClose}
                isLoading={this.props.isLoading}
                groups={this.getGroups()}
            />
        );
    }

    getGroups() {
        if (!this.props.items) {
            return [];
        }

        return this.props.items.map((group) => ({
            key: `group-${group.id}`,
            title: group.name,
            description: group.description,
            type: group.type,
            items: group.elements.map((element) => ({
                key: `item-${element.id}`,
                title: element.name,
                description: i18n.t('pages.shop.itemPrice', { count: element.price }),
                isLoading:
                    this.state.activeElement && this.state.activeElement.type === element.type,
                isDisabled: false,
                onClick: this.handleItemClick.bind(this, element),
            })),
        }));
    }

    /**
     *
     * @param {ShopItemModel} element
     */
    handleItemClick(element) {
        if (this.state.activeElement === null) {
            this.setState({
                activeElement: element,
            });

            this.props.actions.buyItem(this.props.user.id, element.type).then(() => {
                this.setState({
                    activeElement: null,
                });
            });
        }
    }

    handlePageClose() {
        this.props.actions.toUrl(URL_HOME);
    }
}

const mapStateToProps = function (state) {
    return {
        items: state.shop.items,
        isLoading: state.shop.isLoading,
        user: state.profile.info,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                toUrl: push,
                fetchShop,
                buyItem,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageShop);
