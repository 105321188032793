import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { CSSTransition } from 'react-transition-group';
import NotificationUI from './Notification';

export const ANIMATION_TIME = 300;

function NotificationContainerUI(props) {
    return (
        <div id="notification-wrapper">
            <CSSTransition
                in={Boolean(props.notification)}
                classNames="notification"
                timeout={{ enter: ANIMATION_TIME, exit: ANIMATION_TIME }}
                appear={false}
                enter={true}
                exit={true}
                unmountOnExit
            >
                {() => (
                    <div className={'wrapper'}>
                        {props.notification ? (
                            <NotificationUI notification={props.notification} />
                        ) : null}
                    </div>
                )}
            </CSSTransition>
        </div>
    );
}

NotificationContainerUI.propTypes = {
    notification: PropTypes.object,
};

export default NotificationContainerUI;
