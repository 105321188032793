import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToPage, showAdv } from '../../../../store/app';

import DialogSingleGameFinishUI from '../../../../components/Dialog/Types/SingleGameFinish';
import { hideDialog } from '../../../../store/dialog';
import { URL_HOME } from '../../../../config/url';
import PlayUser from '../../../../model/playUser';
import I18n from '../../../../i18n';

import { randomIntFromInterval } from '../../../../utils';
import { ADV_INTERSTITIAL, SEE_ADV_PERCENT } from '../../../../config';

export const DIALOG_SINGLE_GAME_FINISH = 'single-game-finish';

class DialogSingleGameFinish extends PureComponent {
    constructor(props) {
        super(props);

        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    render() {
        let title;

        if (this.props.playUser.isWinner) {
            title = I18n.t('pages.game.youWon');
        } else {
            title = I18n.t('pages.game.timeIsOut');
        }

        return (
            <DialogSingleGameFinishUI
                points={this.props.playUser.pointsWithBonuses}
                onContinueClick={this.handleContinueClick}
                onCancelClick={this.handleCancelClick}
                title={title}
                isWinner={this.props.playUser.isWinner}
            />
        );
    }

    handleContinueClick() {
        this.props.onNewGame();
        this.props.actions.hideDialog();
    }

    handleCancelClick() {
        this.props.actions.hideDialog();
        this.props.actions.goToPage(URL_HOME);

        if (this.props.isAllowSeeAdv && randomIntFromInterval(0, 100) < SEE_ADV_PERCENT) {
            this.props.actions
                .showAdv(ADV_INTERSTITIAL, this.props.user.externalId)
                .catch(() => {});
        }
    }
}

DialogSingleGameFinish.propTypes = {
    onNewGame: PropTypes.func.isRequired,
    playUser: PropTypes.instanceOf(PlayUser).isRequired,
};

const mapStateToProps = function (state) {
    return {
        isAllowSeeAdv: state.app.isAllowSeeAdv,
        user: state.profile.info,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                goToPage,
                hideDialog,
                showAdv,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogSingleGameFinish);
