import axios from 'axios';
import { SERVER_API } from '../../config';

const SET_PAGE = Symbol('SET_PAGE');

function setPage(pageId, text) {
    return {
        type: SET_PAGE,
        pageId,
        text,
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');

function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

export function fetchPage(pageSlug) {
    return function (dispatch) {
        dispatch(setIsLoading(true));

        return axios
            .get(`${SERVER_API}/page`, {
                params: {
                    pageSlug,
                },
            })
            .then(function (response) {
                if (response.data.text) {
                    dispatch(setPage(pageSlug, response.data.text));
                    dispatch(setIsLoading(false));
                }
            });
    };
}

const initialState = {
    pages: {},
    isLoading: false,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_PAGE] = function () {
        const pages = Object.assign({}, state.pages);
        pages[action.pageId] = action.text;

        return {
            ...state,
            pages,
        };
    };

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
