class ShopItemModel {
    id = null;

    type = null;

    name = null;

    price = null;

    constructor(userData) {
        this.id = userData.id;
        this.type = userData.type;
        this.name = userData.name;
        this.price = userData.price;
    }
}

export default ShopItemModel;
