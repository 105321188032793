import ShopCategotyModel from '../../model/shopCategory';
import axios from 'axios';
import { SERVER_API } from '../../config';

import { showNotification } from '../notification';
import NotificationModel, { TYPE_ERROR } from '../../model/notification';
import { setUserInfo } from '../profile';
import UserModel from '../../model/user';
import I18n from '../../i18n';

const SET_SHOP_ITEMS = Symbol('SET_SHOP_ITEMS');

function setShopItems(items) {
    return {
        type: SET_SHOP_ITEMS,
        items,
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');

function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

export function fetchShop() {
    return function (dispatch) {
        dispatch(setIsLoading(true));

        return axios
            .get(`${SERVER_API}/shop`, {
                params: {},
            })
            .then(function (response) {
                if (response.data.items) {
                    const items = response.data.items.map((item) => {
                        return new ShopCategotyModel(item);
                    });

                    dispatch(setShopItems(items));
                    dispatch(setIsLoading(false));
                }
            });
    };
}

export function buyItem(userId, item) {
    return function (dispatch, getState) {
        return axios
            .post(`${SERVER_API}/shop/buy`, {
                userId,
                item,
                session: getState().app.session,
            })
            .then(function (response) {
                dispatch(
                    showNotification(
                        new NotificationModel({
                            title: response.data.message,
                            timeout: 3000,
                        })
                    )
                );

                dispatch(setUserInfo(new UserModel(response.data.user)));
            })
            .catch((error) => {
                dispatch(
                    showNotification(
                        new NotificationModel({
                            title: I18n.t('error'),
                            description: error.response.data.error,
                            type: TYPE_ERROR,
                            timeout: 3000,
                        })
                    )
                );
            });
    };
}

const initialState = {
    items: null,
    request: null,
    isLoading: false,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_SHOP_ITEMS] = function () {
        return {
            ...state,
            items: action.items,
        };
    };

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
