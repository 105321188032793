import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import LazyImage from '../LazyImage';

function UserInfoUI(props) {
    const classes = classNames({
        'user-info': true,
        [props.type]: true,
        'is-active': props.isActive,
    });

    return (
        <div className={classes} onClick={props.onClick}>
            <div className="user-photo">
                <LazyImage classNames={'image'} src={props.photoUrl} />
            </div>
            {props.level ? (
                <React.Fragment>
                    <span className="user-level" data-tip data-for="you-level-tooltip">
                        {props.level}
                    </span>
                    <ReactTooltip id="you-level-tooltip" place="right" type="light" effect="solid">
                        Ваш уровень
                    </ReactTooltip>
                </React.Fragment>
            ) : null}
            {props.text ? <span className="user-text">{props.text}</span> : null}
        </div>
    );
}

UserInfoUI.defaultProps = {
    type: '',
};

UserInfoUI.TYPE = {
    MEDIUM: 'medium',
    SMALL: 'small',
};

UserInfoUI.propTypes = {
    photoUrl: PropTypes.string.isRequired,
    level: PropTypes.number,
    text: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    hasBoost: PropTypes.bool,
    hasBonuses: PropTypes.bool,
    coinsCount: PropTypes.number,
};

export default UserInfoUI;
