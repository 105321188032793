import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import PageCrossUI from '../../../UI/PageCross';
import Button from '../../../UI/Button';

export const STEP_INTO = 'intro';
export const STEP_NEW_GAME = 'new';
export const STEP_CONNECT = 'connect';

function renderButtons(props) {
    switch (props.step) {
        case STEP_INTO:
            return (
                <React.Fragment>
                    <Button text="Создать новую игру" onClick={props.onNewGameStart} />
                    <Button
                        text="Присоединиться к игре"
                        onClick={props.onGameConnect}
                        className={'connect'}
                    />
                    {props.onFriendInvite ? (
                        <Button
                            text="Пригласить друга"
                            onClick={props.onFriendInvite}
                            className={'invite'}
                        />
                    ) : null}
                </React.Fragment>
            );
        case STEP_NEW_GAME:
        case STEP_CONNECT:
            return (
                <React.Fragment>
                    <Button text="Начать" onClick={props.onStart} isLoading={props.isLoading} />
                    {!props.isWaitingFriend ? (
                        <div className={'link'} onClick={props.onBackToMenu}>
                            Назад
                        </div>
                    ) : null}
                </React.Fragment>
            );
        default:
    }
}

function renderText(props) {
    switch (props.step) {
        case STEP_INTO:
            return <div>Для начала игры выберите одну из следующих опций</div>;
        case STEP_NEW_GAME:
            return (
                <div>
                    <div className="pin-text">
                        {props.isWaitingFriend
                            ? 'Ожидаем подключения соперника...'
                            : 'Сообщите другу код игры, начните игру и ждите соперника'}
                    </div>
                    <div className="pin">
                        <span className="pin-value">{props.gameCode}</span>
                        <span className="pin-helper">код игры</span>
                    </div>
                </div>
            );
        case STEP_CONNECT:
            return (
                <div>
                    <div className="code-text">Введите код для игры от друга и присоединяйтесь</div>
                    <div className="code">
                        <input
                            autoFocus={true}
                            value={props.gameCode}
                            onChange={props.onCodeChange}
                            minLength={'4'}
                            maxLength={'4'}
                        />
                        {props.errorMessage ? (
                            <div className={'error-message'}>{props.errorMessage}</div>
                        ) : null}
                    </div>
                </div>
            );
        default:
    }
}

function PageGameUI(props) {
    return (
        <div id="page-game-friend" className="page with-background">
            {props.onPageClose ? <PageCrossUI onClick={props.onPageClose} /> : null}
            <h1>Игра с другом</h1>
            <div className="content">
                <div className={'game-friend-block'}>
                    <div className="image">
                        <img src={'/images/icons/game-with-friend.svg'} alt={''} />
                    </div>
                    <div className="info">
                        <div className="text">{renderText(props)}</div>
                        <div className="buttons">{renderButtons(props)}</div>
                    </div>
                </div>
                <div className={'helper'}>
                    <span>
                        Приглашайте друзей, рассказывайте друзьям об игре и соревнйтесь с ними, ведь
                        играть вместе намного интереснее.
                    </span>
                </div>
            </div>
        </div>
    );
}

PageGameUI.propTypes = {
    step: PropTypes.oneOf([STEP_INTO, STEP_NEW_GAME, STEP_CONNECT]),
    onPageClose: PropTypes.func,
    gameCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onNewGameStart: PropTypes.func.isRequired,
    onGameConnect: PropTypes.func.isRequired,
    onBackToMenu: PropTypes.func.isRequired,
    onStart: PropTypes.func.isRequired,
    onFriendInvite: PropTypes.func,
    onCodeChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    isWaitingFriend: PropTypes.bool,
};

export default PageGameUI;
