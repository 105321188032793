import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PageProfileUI from '../../../components/Pages/Profile';
import { URL_HOME, URL_PROFILE } from '../../../config/url';
import { goBack } from 'connected-react-router';
import { goToPage } from '../../../store/app';
import { fetchUser, setProfile, setUserLastGames, fetchLastUserGames } from '../../../store/user';
import i18n from '../../../i18n';

class PageUser extends PureComponent {
    static navigationOptions = ({ navigation }) => {
        const user = navigation.getParam('user');

        return {
            title: user ? user.firstName : i18n.t('pages.user.title'),
        };
    };

    constructor(props) {
        super(props);

        this.handlePageClose = this.handlePageClose.bind(this);

        this.state = {
            isLoading: true,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && this.props.lastGames) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isLoading: false,
            });
        }

        const { navigation, match } = this.props;

        let isSameUser = true;

        if (match) {
            isSameUser = prevProps.match.params.userId === match.params.userId;
        } else if (navigation && navigation.getParam('user')) {
            isSameUser =
                prevProps.navigation.getParam('user').id === navigation.getParam('user').id;
        }

        if (!isSameUser) {
            this.fetchPageData(this.getUserId());
        }
    }

    componentWillUnmount() {
        this.props.actions.setProfile(null);
        this.props.actions.setUserLastGames(null);
    }

    componentDidMount() {
        this.fetchPageData(this.getUserId());
    }

    getUserId() {
        let userId;

        if (this.props.navigation && this.props.navigation.getParam('user')) {
            const user = this.props.navigation.getParam('user');
            userId = user.id;
        } else if (this.props.match && this.props.match.params.userId) {
            userId = this.props.match.params.userId;
        }

        return userId;
    }

    fetchPageData(userId) {
        this.setState({
            isLoading: true,
        });

        this.props.actions.setProfile(null);
        this.props.actions.setUserLastGames(null);
        this.props.actions.fetchUser(userId);
        this.props.actions.fetchLastUserGames(userId);
    }

    render() {
        return (
            <PageProfileUI
                onPageClose={this.handlePageClose}
                isOwnProfile={false}
                pageTitle={this.getPageTitle()}
                user={this.props.user}
                isLoading={this.state.isLoading}
                loadingText={'Загрузка профиля'}
                lastGames={this.getLastGames()}
            />
        );
    }

    handlePageClose() {
        if (this.props.location.state && this.props.location.state.hasBack) {
            // We came from other page
            this.props.actions.goBack();
        } else {
            this.props.actions.goToPage(URL_HOME);
        }
    }

    getPageTitle() {
        let title = 'Профиль игрока';

        if (this.props.location) {
            const historyUser = this.props.location.state && this.props.location.state.user;

            if (historyUser) {
                title = `Игрок ${historyUser.firstName}`;
            } else if (this.props.user) {
                title = `Игрок ${this.props.user.firstName}`;
            }
        }

        return title;
    }

    getLastGames() {
        if (!this.props.lastGames) {
            return [];
        }

        return this.props.lastGames.map((game) /** @param game GameResultModel */ => {
            return {
                key: game.id,
                game,
                onFirstPlayerClick: this.handleOpenUser.bind(this, game.firstPlayer),
                onSecondPlayerClick: this.handleOpenUser.bind(this, game.secondPlayer),
            };
        });
    }

    handleOpenUser(gameUser) {
        this.props.actions.goToPage(`${URL_PROFILE}/${gameUser.id}`, {
            user: gameUser,
            hasBack: true,
        });
    }
}

const mapStateToProps = function (state) {
    return {
        user: state.user.user,
        lastGames: state.user.lastGames,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                goToPage,
                goBack,
                fetchUser,
                setProfile,
                setUserLastGames,
                fetchLastUserGames,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageUser);
