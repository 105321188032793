import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import classNames from 'classnames';
import PageCross from '../../../UI/PageCross';
import PlayFieldItemUI from '../PlayFieldItem';

function PlayFieldUI(props) {
    const classes = classNames({
        items: true,
        [`length-${props.items.length}`]: true,
        'is-disabled': !props.isActive
    });

    return (
        <div id="page-game-playfield" className="page">
            {props.onPageClose ? <PageCross onClick={props.onPageClose} /> : null}
            <div className="game-panel">
                {props.gamePanel}
            </div>
            <div className={classes}>
                {props.items.map(entry => {
                    return (
                        <PlayFieldItemUI
                            key={entry.key}
                            item={entry.item}
                            onClick={entry.onClick}
                        />
                    );
                })}
            </div>
            {!props.isActive ? (
                <div className={'hint'}>
                    <span>Ход соперника</span>
                </div>
            ) : null}
            <div className="helpers-panel-wrapper">
                {props.helpersPanel}
            </div>
        </div>
    );
}

PlayFieldUI.propTypes = {
    gamePanel: PropTypes.element.isRequired,
    helpersPanel: PropTypes.element.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        item: PropTypes.object.isRequired,
        onClick: PropTypes.func.isRequired,
    })).isRequired,
    isActive: PropTypes.bool.isRequired,
    onPageClose: PropTypes.func,
};

export default PlayFieldUI;
