let vkBridge;

export function setVkBridge(bridge) {
    vkBridge = bridge;
}

export function getVkBridge() {
    return vkBridge;
}

export default getVkBridge;
