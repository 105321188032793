import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import DialogUI from '../../components/Dialog';

import DialogUserDisconnected, { DIALOG_USER_DISCONNECTED } from './Types/UserDisconnected';
import DialogSingleGameFinish, { DIALOG_SINGLE_GAME_FINISH } from './Types/SingleGameFinish';
import DialogMultiplayerGameFinish, {
    DIALOG_MULTIPLAYER_GAME_FINISH,
} from './Types/MultiplayerGameFinish';
import DialogAlert, { DIALOG_ALERT } from './Types/Alert';

const MAP = {
    [DIALOG_USER_DISCONNECTED]: DialogUserDisconnected,
    [DIALOG_SINGLE_GAME_FINISH]: DialogSingleGameFinish,
    [DIALOG_MULTIPLAYER_GAME_FINISH]: DialogMultiplayerGameFinish,
    [DIALOG_ALERT]: DialogAlert,
};

class Dialog extends PureComponent {
    render() {
        let dialog;

        if (this.props.activeDialogType && MAP[this.props.activeDialogType]) {
            const ActiveDialog = MAP[this.props.activeDialogType];

            dialog = React.createElement(ActiveDialog, this.props.openParams, null);
        }

        return dialog ? <DialogUI type={this.props.activeDialogType}>{dialog}</DialogUI> : null;
    }
}

const mapStateToProps = function (state) {
    return {
        activeDialogType: state.dialog.activeDialogType,
        openParams: state.dialog.openParams,
    };
};

const mapDispatchToProps = function () {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
