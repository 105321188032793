import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DialogAlertUI from '../../../../components/Dialog/Types/Alert';
import { hideDialog } from '../../../../store/dialog';

export const DIALOG_ALERT = 'alert';

class DialogAlert extends PureComponent {
    constructor(props) {
        super(props);

        this.handleContinueClick = this.handleContinueClick.bind(this);
    }

    render() {
        return (
            <DialogAlertUI
                title={this.props.title}
                message={this.props.message}
                onContinueClick={this.handleContinueClick}
            />
        );
    }

    handleContinueClick() {
        this.props.actions.hideDialog();
    }
}

DialogAlert.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
};

const mapStateToProps = function (state) {
    return {
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                hideDialog,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAlert);
