const SET_ACTIVE_DIALOG = Symbol('SET_ACTIVE_DIALOG');

export function showDialog(activeDialogType = false, openParams = {}) {
    return {
        type: SET_ACTIVE_DIALOG,
        activeDialogType,
        openParams,
    };
}

export function hideDialog() {
    return function (dispatch) {
        dispatch(showDialog(null));
    };
}

const initialState = {
    activeDialogType: null,
    openParams: {},
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_ACTIVE_DIALOG] = function () {
        return {
            ...state,
            activeDialogType: action.activeDialogType,
            openParams: action.openParams,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call(null);
    }

    return state;
}
