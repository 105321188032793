import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import classNames from 'classnames'
import FieldItemModel from '../../../../model/fieldItem';

function FieldItemUI(props) {
    const classes = classNames({
        'flipper': true,
        'is-opened': props.item.isOpened
    });

    const style = {
        'backgroundImage': `url('${props.item.imagePath}')`
    };

    return (
        <div className="play-field-item">
            <div className={classes} onClick={props.onClick}>
                <div className="cover">
                    <span>мемотон</span>
                </div>
                <div className="value">
                    <span
                        style={props.item.isOpened ? style : null}
                    />
                </div>
            </div>
        </div>
    );
}

FieldItemUI.propTypes = {
    item: PropTypes.instanceOf(FieldItemModel).isRequired,
    onClick: PropTypes.func.isRequired
};

export default FieldItemUI;