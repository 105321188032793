import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';
import Notification, { TYPE_ERROR } from '../../../model/notification';

function NotificationUI(props) {
    const classes = classNames({
        notification: true,
        'is-error': props.notification.type === TYPE_ERROR,
    });

    return (
        <div className={classes}>
            <div className={'title'}>{props.notification.title}</div>
            {props.notification.description ? (
                <div className={'description'}>{props.notification.description}</div>
            ) : null}
            <audio autoPlay>
                <source src="/sounds/notification.mp3" type="audio/mpeg" />
            </audio>
        </div>
    );
}

NotificationUI.propTypes = {
    notification: PropTypes.instanceOf(Notification),
};

export default NotificationUI;
