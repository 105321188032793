class GameHelperModel {
    id = null;

    title = null;

    imagePath = null;

    isActive = null;

    price = null;

    constructor(userData) {
        this.id = userData.id;
        this.title = userData.title;
        this.imagePath = userData.image_path;
        this.isActive = userData.is_active;
        this.price = userData.price;
    }
}

export default GameHelperModel;
