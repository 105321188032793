import FieldItemModel from './fieldItem';

class RoundModel {
    constructor(data, pixelRatio) {
        this.id = data.id;
        this.openedItemsCount = data.opened_items_count;
        this.items = data.items.map((item) => {
            return new FieldItemModel(item, pixelRatio);
        });
    }
}

export default RoundModel;
