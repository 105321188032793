import { AsyncStorage } from 'react-native';

const STORAGE_NAME = 'AppStorage';

class Storage {
    getItem(key) {
        try {
            return AsyncStorage.getItem(this._getKey(key));
        } catch (error) {
            console.log(`Storage: can not get item`);
        }
    }

    getItems(keys) {
        try {
            const items = keys.map(this._getKey);
            return AsyncStorage.multiGet(items).then((result) => {
                const data = {};

                keys.forEach((key) => {
                    const storageKey = this._getKey(key);

                    const keyItem = result.find((value) => {
                        return value[0] === storageKey;
                    });

                    if (keyItem && keyItem[1] !== null) {
                        data[key] = keyItem[1];
                    }
                });

                return data;
            });
        } catch (error) {
            console.log(`Storage: can not get items`);
        }
    }

    setItem(key, value) {
        try {
            return AsyncStorage.setItem(this._getKey(key), value);
        } catch (error) {
            console.log(`Storage: can not insert item`);
        }
    }

    /**
     *
     * @param {object} items
     * @returns {*|Promise}
     */
    setItems(items = {}) {
        const insertItems = Object.keys(items).map((key) => {
            return [this._getKey(key), items[key]];
        });

        try {
            return AsyncStorage.multiSet(insertItems);
        } catch (error) {
            console.log(`Storage: can not insert items`);
        }
    }

    /**
     *
     * @param {Array|String} keys
     */
    remove(keys) {
        try {
            if (Array.isArray(keys)) {
                const items = keys.map(this._getKey);
                return AsyncStorage.multiRemove(items);
            } else {
                return AsyncStorage.removeItem(this._getKey(keys));
            }
        } catch (error) {
            console.log(`Storage: can not insert items`);
        }
    }

    _getKey(key) {
        return `@${STORAGE_NAME}:${key}`;
    }
}

export default new Storage();
