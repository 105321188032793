import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import PageCross from '../../UI/PageCross';
import LoadingUI from '../../Loading';

function PageMoneyUI(props) {
    return (
        <div id="page-money" className="page with-background">
            <PageCross onClick={props.onPageClose} />
            <h1>Пополнение баланса</h1>

            {props.isLoading ? (
                <LoadingUI loadingText={props.loadingText} />
            ) : (
                <div className="price-block">
                    <div className="items">{props.moneyElements}</div>
                    <div className="sub-info">
                        Используйте монеты для подсказок и покупки бонусов в игре.
                    </div>
                </div>
            )}
        </div>
    );
}

PageMoneyUI.propTypes = {
    moneyElements: PropTypes.array.isRequired,
    onPageClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadingText: PropTypes.string,
};

export default PageMoneyUI;
