import ShopItemModel from './shopItem';

class ShopCategotyModel {
    id = null;

    type = null;

    name = null;

    description = null;

    elements = null;

    constructor(userData) {
        this.id = userData.id;
        this.type = userData.type;
        this.name = userData.name;
        this.description = userData.description;

        if (Array.isArray(userData.elements)) {
            this.elements = userData.elements.map((item) => {
                return new ShopItemModel(item);
            });
        }
    }
}

export default ShopCategotyModel;
