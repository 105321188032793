import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import UserModel from '../../../model/user';
import ButtonUI from '../../UI/Button';

function PageDefaultUI(props) {
    const user = props.user;

    return (
        <div id="page-default">
            <div className="app-logo">
                <img src={'/images/logo.png'} alt={''} />
            </div>
            <div className="hello">Привет, {user.firstName}</div>
            <div className="menu">
                <ButtonUI
                    text={'Случайная игра'}
                    className="medium random"
                    icon={'/images/icons/random-game.svg'}
                    onClick={props.onRandomGamePageClick}
                />
                <ButtonUI
                    text={'Игра с другом'}
                    className="medium friend"
                    icon={'/images/icons/game-with-friend.svg'}
                    onClick={props.onFriendGamePageClick}
                />
                <ButtonUI
                    text={'Одиночная игра'}
                    className="medium single"
                    icon={'/images/icons/single-game.svg'}
                    onClick={props.onSingleGamePageClick}
                />
                {props.onShopPageClick ? (
                    <ButtonUI
                        text={'Магазин'}
                        className="medium shop"
                        icon={'/images/icons/shopping-cart.svg'}
                        onClick={props.onShopPageClick}
                    />
                ) : (
                    <ButtonUI
                        text={'Пригласить друга'}
                        className="medium shop"
                        icon={'/images/icons/invite-user.svg'}
                        onClick={props.onInviteClick}
                    />
                )}
                <ButtonUI
                    text={'Рейтинг'}
                    className="medium rating"
                    icon={'/images/icons/rating.svg'}
                    onClick={props.onRatingPageClick}
                />
                <ButtonUI
                    text={'Ваш профиль'}
                    className="medium profile"
                    icon={'/images/icons/profile.svg'}
                    onClick={props.onProfilePageClick}
                />
            </div>
        </div>
    );
}

PageDefaultUI.propTypes = {
    user: PropTypes.instanceOf(UserModel),
    onRatingPageClick: PropTypes.func.isRequired,
    onShopPageClick: PropTypes.func,
    onGroupClick: PropTypes.func,
    onInviteClick: PropTypes.func,
    onProfilePageClick: PropTypes.func.isRequired,
    onSingleGamePageClick: PropTypes.func.isRequired,
    onFriendGamePageClick: PropTypes.func.isRequired,
    onRandomGamePageClick: PropTypes.func.isRequired,
};

export default PageDefaultUI;
