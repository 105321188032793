import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function PageGameCountdownUI(props) {
    return (
        <React.Fragment>
            <div id="page-game-countdown" className="page">
                <TransitionGroup>
                    <CSSTransition
                        key={props.value}
                        classNames="coutndown"
                        appear={true}
                        timeout={{ enter: 500, exit: 600 }}
                        enter={true}
                        exit={true}
                    >
                        <div className="value">{props.value}</div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
            <audio autoPlay>
                <source src="/sounds/start-game.mp3" type="audio/mpeg" />
            </audio>
        </React.Fragment>
    );
}

PageGameCountdownUI.propTypes = {
    value: PropTypes.number.isRequired,
};

export default PageGameCountdownUI;
