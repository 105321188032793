import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function NoPlayersUI(props) {
    return (
        <div className={'no-gamers'}>
            Кажется соперников нет, <a onClick={props.onButtonClick}>начать одиночную игру</a> ?
        </div>
    );
}

NoPlayersUI.propTypes = {
    onButtonClick: PropTypes.func,
};

export default NoPlayersUI;
