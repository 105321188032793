import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import HelperItemUI from './Item';

function HelpersPanelUI(props) {
    return (
        <div id="helpers-panel">
            <div className="helper-items">
                {props.items.map((entry) => {
                    return (
                        <HelperItemUI
                            key={entry.key}
                            id={entry.id}
                            title={entry.title}
                            isActive={entry.isActive}
                            onClick={entry.onClick}
                        />
                    );
                })}
            </div>
            <div className="text">ваши подсказки</div>
        </div>
    );
}

HelpersPanelUI.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            id: PropTypes.string,
            title: PropTypes.string,
            isActive: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ),
};

export default HelpersPanelUI;
