import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import classNames from 'classnames';
import PlayUser from '../../../../model/playUser';
import UserInfoUI from '../../../UI/UserInfo';

function MultiplayerGamePanelUI(props) {

    const timeStyle = classNames({
        'time-left': true,
        'small': props.seconds >= 100
    });

    const { playerUserStat, opponentUserStat, seconds, activeUserId } = props;

    return (
        <div id="multiplayer-game-panel">
            <div className="statistic left">
                {
                    activeUserId === playerUserStat.userId ?
                        <div className={'step-info'}>Ход игрока {playerUserStat.name}</div> :
                        null
                }
                <UserInfoUI
                    photoUrl={playerUserStat.photo}
                    type={UserInfoUI.TYPE.SMALL}
                    isActive={activeUserId === playerUserStat.userId}
                />
                <div className="stat-block attempts">
                    <div className="value">
                        <img className="icon" src="/images/icons/checked.svg" alt="" />
                        {playerUserStat.rightAnswersCount}
                    </div>
                    <span>угадано</span>
                </div>
                <div className="stat-block attempts">
                    <div className="value">
                        <img className="icon" src="/images/icons/dice.svg" alt="" />
                        {playerUserStat.answersCount}
                    </div>
                    <span>ходов</span>
                </div>
                <div className="stat-block points">
                    <div className="value">
                        <img className="icon" src="/images/icons/star.svg" alt="" />
                        {playerUserStat.points}
                    </div>
                    <span>очков</span>
                </div>
            </div>
            <div className={timeStyle}>
                <span>{seconds}</span>
            </div>
            <div className="statistic right">
                {
                    activeUserId === opponentUserStat.userId ?
                    <div className={'step-info'}>Ход игрока {opponentUserStat.name}</div> :
                    null
                }
                <UserInfoUI
                    photoUrl={opponentUserStat.photo}
                    type={UserInfoUI.TYPE.SMALL}
                    isActive={activeUserId === opponentUserStat.userId}
                />
                <div className="stat-block attempts">
                    <div className="value">
                        <img className="icon" src="/images/icons/checked.svg" alt="" />
                        {opponentUserStat.rightAnswersCount}
                    </div>
                    <span>угадано</span>
                </div>
                <div className="stat-block attempts">
                    <div className="value">
                        <img className="icon" src="/images/icons/dice.svg" alt="" />
                        {opponentUserStat.answersCount}
                    </div>
                    <span>ходов</span>
                </div>
                <div className="stat-block points">
                    <div className="value">
                        <img className="icon" src="/images/icons/star.svg" alt="" />
                        {opponentUserStat.points}
                    </div>
                    <span>очков</span>
                </div>
            </div>
        </div>
    );
}

MultiplayerGamePanelUI.propTypes = {
    seconds: PropTypes.number.isRequired,
    playerUserStat: PropTypes.instanceOf(PlayUser).isRequired,
    opponentUserStat: PropTypes.instanceOf(PlayUser).isRequired,
    activeUserId: PropTypes.number
};

export default MultiplayerGamePanelUI;
