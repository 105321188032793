import React, { useState, useEffect } from 'react';

const PIXEL_IMG =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8c+/BfwAI0wObTDz+bwAAAABJRU5ErkJggg==';
const SPIN =
    'data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjM4IiBoZWlnaHQ9IjM4IiB2aWV3Qm94PSIwIDAgMzggMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjZmZmIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj4KICAgICAgICAgICAgPGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMzYgMThjMC05Ljk0LTguMDYtMTgtMTgtMTgiPgogICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0KICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iCiAgICAgICAgICAgICAgICAgICAgdHlwZT0icm90YXRlIgogICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiCiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIKICAgICAgICAgICAgICAgICAgICBkdXI9IjFzIgogICAgICAgICAgICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+CiAgICAgICAgICAgIDwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==';

const LazyImage = ({ src, classNames, alt = '', asImage = false }) => {
    const elementRef = React.createRef();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        let observer;
        let didCancel = false;

        const elementRefElement = elementRef.current;

        if (
            !('IntersectionObserver' in window) ||
            !('IntersectionObserverEntry' in window) ||
            !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
        ) {
            // Old browsers fallback
            setIsVisible(true);
        } else {
            if (IntersectionObserver && elementRefElement) {
                observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (
                                !didCancel &&
                                (entry.intersectionRatio > 0 || entry.isIntersecting)
                            ) {
                                setIsVisible(true);

                                if (observer && observer.unobserve && elementRefElement) {
                                    observer.unobserve(elementRefElement);
                                }
                            }
                        });
                    },
                    {
                        threshold: 0.01,
                        rootMargin: '35%',
                    }
                );
                observer.observe(elementRefElement);
            }
        }

        return () => {
            didCancel = true;
            // on component cleanup, we remove the listner
            if (observer && observer.unobserve && elementRefElement) {
                observer.unobserve(elementRefElement);
            }
        };
    }, [src, elementRef]);

    return asImage ? (
        <img
            ref={elementRef}
            className={classNames}
            src={isVisible && src ? src : PIXEL_IMG}
            alt={alt}
        />
    ) : (
        <div
            title={alt}
            ref={elementRef}
            className={classNames}
            style={
                isVisible && src
                    ? {
                          backgroundImage: `url(${src})`,
                      }
                    : {
                          backgroundImage: `url(${SPIN})`,
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '40%',
                          backgroundColor: '#a32958',
                      }
            }
        ></div>
    );
};

export default LazyImage;
