import RatingUserModel from '../../model/ratingUser';
import axios from 'axios';
import { SERVER_API } from '../../config';

const SET_TOP_USERS = Symbol('SET_TOP_USERS');

export function setTopUsers(users) {
    return {
        type: SET_TOP_USERS,
        users,
    };
}

export function fetchTopUsers() {
    return function (dispatch) {
        return axios
            .get(`${SERVER_API}/top`, {
                params: {
                    limit: 5,
                },
            })
            .then(function (response) {
                if (response.data.users) {
                    const users = response.data.users.map((user) => {
                        return new RatingUserModel(user);
                    });

                    dispatch(setTopUsers(users));
                }
            });
    };
}

const initialState = {
    users: [],
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_TOP_USERS] = function () {
        return {
            ...state,
            users: action.users,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
