export default {
    gameName: '',
    greeting: 'Привет, {{userName}}!',
    appLoading: 'Загрузка приложения',
    exitFromApp: 'Выход',
    exitFromAppDescription: 'Вы действительно хотите выйти?',
    exit: 'Выйти',
    cancel: 'Отмена',
    submit: 'Отправить',
    signUp: 'Зарегистрироваться',
    tabs: {
        game: 'Игра',
        rating: 'Рейтинг',
        shop: 'Бонусы',
        profile: 'Профиль',
    },
    pages: {
        profile: {
            title: 'Ваш профиль',
            lastGames: 'Недавние игры',
            noGamesYet: 'Игр еще не было',
        },
        rating: {
            title: 'Рейтинг игроков',
            loadUsersList: 'Загрузка игроков',
        },
        shop: {
            title: 'Магазин бонусов',
            itemPrice: {
                one: 'Стоимость: {{count}} монета',
                few: 'Стоимость: {{count}} монеты',
                many: 'Стоимость: {{count}} монет',
                other: 'Стоимость: {{count}} монет',
            },
            loadingText: 'Загрузка банусов',
        },
        user: {
            title: 'Профиль игрока',
        },
        game: {
            youWon: 'Вы победили!',
            youLose: 'Увы, вы проиграли',
            draw: 'Ничья!',
            timeIsOut: 'Время вышло',
            endGame: 'Завершить',
        },
        join: {
            title: 'Присоедениться',
            yourEmailAddress: 'Email адресс',
            password: 'Пароль',
            yourPassword: 'Пароль',
            enterPassword: 'Введите пароль',
            credentials: 'Учетные данные',
            firstName: 'Ваше имя',
            lastName: 'Ваша фамилия',
            welcome: 'Добро пожаловать',
            welcomeDescription: 'Для регистрации пожалуйста заполните \n немного информации о себе',
            enterFirstName: 'Введите имя',
            enterLastName: 'Введите фамилию',
            uploadActionText: 'Нажмите для загрузки вашей фотографии',
        },
    },
    points: {
        one: '{{count}} очко',
        few: '{{count}} очка',
        many: '{{count}} очков',
        other: '{{count}} очков',
    },
    coinsCount: {
        one: '{{count}} монета',
        few: '{{count}} монеты',
        many: '{{count}} монет',
        other: '{{count}} монет',
    },
    inviteSubject: 'Приглашаю тебя в игру Мемотон!',
    inviteBody: `Привет! Я приглашаю тебя сыграть со мной в игру Мемотон! Скорее заходи на https://memoton.witgames.info/`,
    inviteBodyShort: `Привет! Я приглашаю тебя сыграть со мной в игру Мемотон!`,
    inviteWallPost: 'Я играю в игру Мемотон! Присоединяйтесь и сыграйте со мной.',
    userRequestVk:
        'Привет, {{name}}! Хочу сыграть с тобой в игре Мемотон! Скорее заходи в игру {{url}}',
    day: 'День',
    week: 'Неделя',
    month: 'Месяц',
    total: 'Всего',
    totalPoints: 'Всего очков: {{points}}',
    totalPointsWithoutNumber: 'всего очков',
    games: 'игр',
    winGames: 'побед',
    moves: {
        one: '{{count}} ход',
        few: '{{count}} хода',
        many: '{{count}} ходов',
        other: '{{count}} ходов',
    },
    openCards: {
        one: '{{count}} угадан',
        few: '{{count}} угадано',
        many: '{{count}} угадано',
        other: '{{count}} угаданных',
    },
    authorization: 'Авторизация',
    todayTop: 'Топ сегодня',
    helloUser: 'Привет, {{userName}}',
    vkontakte: 'Вконтакте',
    odnoklassniki: 'Одноклассники',
    facebook: 'Facebook',
    authHelperText:
        'Для начала игры, пожалуйста, пройдит авторизацию через удобный для вас способ.',
    singleGame: 'Одиночная игра',
    randomGame: 'Случайная игра',
    coins: 'Монет',
    level: 'Уровень',
    error: 'Ошибка',
    loadingGame: 'Идет создание игры',
    opponentSearch: 'Идет поиск соперника',
    waitingSecondPlayer: 'ожидаем второго игрока',
    cancelIt: 'Отменить',
    roundValue: 'Раунд {{value}}',
    rightAnswersCount: 'Угадано',
    answersCount: 'Ходов',
    pointsValue: 'Очков',
    yourHelpers: 'Ваши подсказки',
    helperActivated: 'Подсказка активирована',
    memoton: 'Мемотон',
    noActiveGames: 'Кажется соперников нет. Может сыграть в одиночную игру?',
    opponentMove: 'Ход соперника',
    opponentMoveHint: 'ждите',
    userDisconnected: 'Соперник отключился',
    newGame: 'Новая игра',
    earnedPoints: 'Заработанные очки',
    noInternetConnection: 'Нет интернет подключения',
    noInternetConnectionText:
        'Игра не может работать без инернет соедения. Пожалуйста включите сеть.',
    levelProgress: 'Прогресс уровня',
    noServerConnection: 'Нет подключения к серверу',
    noServerConnectionText:
        'Наш сервер временно не доступен. \n Подождите, мы работаем над востановлением.',
    join: 'Присоединиться',
    or: 'или',
    weNeedImage: 'Пожалуйста загрузите свое фото',
    imagePermissionError: 'Пожалуйста, разрешите выбор фото для загрузки ее в ваш профиль',
    privacyPolicy: 'Политика конфиденциальности',
};
