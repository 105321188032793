import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import classNames from 'classnames';

const ButtonGroupItemUI = function (props) {
    const classes = classNames({
        'group-button': true,
        'is-active': props.isActive === true,
    });

    return (
        <button className={`${classes} ${props.className}`} type="button" onClick={props.onClick}>
            <span className="internal">
                {props.icon ? <img src={props.icon} className="icon" alt="" /> : null}
                <span className="text">{props.text}</span>
            </span>
        </button>
    );
};

ButtonGroupItemUI.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
};

export default ButtonGroupItemUI;
