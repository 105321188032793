class FieldItemModel {
    id = null;

    value = null;

    imagePath = null;

    isOpened = false;

    isFound = false;

    constructor(data, pixelRatio) {
        this.id = data.id;
        this.value = data.value;

        if (pixelRatio > 2) {
            this.imagePath = data.image_path;
        } else {
            this.imagePath = data.image_path.replace('.png', '.small.png');
        }

        console.log(this.imagePath);
    }
}

export default FieldItemModel;
