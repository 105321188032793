import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import PageCross from '../../UI/PageCross';
import LoadingUI from '../../Loading';

function PageHelpUI(props) {
    const renderText = (text) => {
        return (
            <div className="textBlock">
                <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        );
    };

    return (
        <div id="page-help" className="page with-background">
            <PageCross onClick={props.onPageClose} />
            <h1>Помощь по игре</h1>
            {props.isLoading ? (
                <LoadingUI loadingText={props.loadingText} />
            ) : (
                renderText(props.text)
            )}
        </div>
    );
}

PageHelpUI.propTypes = {
    onPageClose: PropTypes.func.isRequired,
    text: PropTypes.string,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,
};

export default PageHelpUI;
