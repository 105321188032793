import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PageCross = function (props) {
    return (
        <div className="page-cross" onClick={props.onClick}>
            <img src={'/images/icons/cancel.svg'} alt={''} />
        </div>
    );
};

PageCross.propTypes = {
    onClick: PropTypes.func,
};

export default PageCross;
