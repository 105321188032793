import axios from 'axios';
import { SERVER_API } from '../../config';
import GameModel from '../../model/game';

const SET_IS_GAME_STARTED = Symbol('SET_IS_GAME_STARTED');
export function setIsGameStarted(isGameStarted = false, game = null, round = null) {
    return {
        type: SET_IS_GAME_STARTED,
        isGameStarted,
        game,
        round,
    };
}

const SET_TIMER_VALUE = Symbol('SET_TIMER_VALUE');

export function setTimerValue(timer) {
    return {
        type: SET_TIMER_VALUE,
        timer,
    };
}

const SET_ROUND = Symbol('SET_ROUND');
export function setRound(round) {
    return {
        type: SET_ROUND,
        round,
    };
}

const SET_HELPERS = Symbol('SET_HELPERS');
export function setHelpers(helpers) {
    return {
        type: SET_HELPERS,
        helpers,
    };
}

export function checkIfFriendGameExits(pin) {
    return function () {
        return axios
            .get(`${SERVER_API}/check-friend-game`, {
                params: {
                    pin,
                },
            })
            .then(function (response) {
                return new GameModel(response.data.game);
            });
    };
}

const SET_PLAY_USER = Symbol('SET_PLAY_USER');
export function setPlayUser(userId, playUser) {
    return {
        type: SET_PLAY_USER,
        userId,
        playUser,
    };
}

const REMOVE_USERS_STATISTIC = Symbol('REMOVE_USERS_STATISTIC');
export function removeUsersStatistic() {
    return {
        type: REMOVE_USERS_STATISTIC,
    };
}

const SET_ACTIVE_USER_ID = Symbol('SET_ACTIVE_USER_ID');
export function setActiveUserId(activeUserId) {
    return {
        type: SET_ACTIVE_USER_ID,
        activeUserId,
    };
}

const initialState = {
    isGameStarted: false,
    game: null,
    round: null,
    helpers: [],
    usersStatistic: {},
    activeUserId: null,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_IS_GAME_STARTED] = function () {
        return {
            ...state,
            isGameStarted: action.isGameStarted,
            game: action.game,
            round: action.round,
        };
    };

    exec[SET_TIMER_VALUE] = function () {
        return {
            ...state,
            timer: action.timer,
        };
    };

    exec[SET_ROUND] = function () {
        return {
            ...state,
            round: action.round,
        };
    };

    exec[SET_HELPERS] = function () {
        return {
            ...state,
            helpers: action.helpers,
        };
    };

    exec[SET_PLAY_USER] = function () {
        const usersStatistic = Object.assign({}, state.usersStatistic);
        usersStatistic[action.userId] = action.playUser;

        return {
            ...state,
            usersStatistic,
        };
    };

    exec[REMOVE_USERS_STATISTIC] = function () {
        return {
            ...state,
            usersStatistic: {},
        };
    };

    exec[SET_ACTIVE_USER_ID] = function () {
        return {
            ...state,
            activeUserId: action.activeUserId,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
