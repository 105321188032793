import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HelpersPanelUI from '../../../../../components/Pages/Game/HelpersPanel';
import { EVENT_CLIENT_GAME_USE_HELPER, EVENT_SERVER_GAME_UPDATE_HELPERS } from '../../../../../config/socket';
import { setHelpers } from '../../../../../store/game';
import GameHelperModel from '../../../../../model/gameHelper';

class HelpersPanel extends PureComponent {
    constructor(props) {
        super(props);

        this.onUpdateHelpers = this.onUpdateHelpers.bind(this);
    }

    render() {
        return (
            <HelpersPanelUI
                items={this.getHelpers()}
            />
        );
    }

    componentDidMount() {
        this.props.socket.on(EVENT_SERVER_GAME_UPDATE_HELPERS, this.onUpdateHelpers);
    }

    componentWillUnmount() {
        this.props.socket.off(EVENT_SERVER_GAME_UPDATE_HELPERS, this.onUpdateHelpers);
    }

    getHelpers() {
        return this.props.helpers.map(helper => {
            const isActive = this.props.user.id === this.props.activeUserId && helper.isActive;

            return {
                key: helper.id,
                id: helper.id,
                title: helper.title,
                isActive,
                onClick: this.handleClickHelper.bind(this, helper)
            };
        });
    }

    handleClickHelper(helper) {
        this.props.socket.emit(EVENT_CLIENT_GAME_USE_HELPER, {
            helperId: helper.id,
            gameId: this.props.game.id
        });
    }

    /**
     *
     * @param data
     * @param {RoundModel} data.helpers
     */
    onUpdateHelpers(data) {
        const helpers = data.helpers.map(helper => new GameHelperModel(helper));
        this.props.actions.setHelpers(helpers);
    }
}

HelpersPanel.propTypes = {};

const mapStateToProps = function (state) {
    return {
        socket: state.app.socket,
        game: state.game.game,
        helpers: state.game.helpers,
        activeUserId: state.game.activeUserId,
        user: state.profile.info,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators({
            setHelpers
        }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpersPanel);
