import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

function GamePanelUI(props) {

    const timeStyle = classNames({
        'time-left': true,
        'small': props.seconds >= 100
    });

    return (
        <div id="game-panel">
            <div className="round-text">
                <TransitionGroup>
                    <CSSTransition
                        key={props.roundValue}
                        classNames="round-value"
                        appear={false}
                        enter={true}
                        timeout={{ enter: 500 }}
                        exit={false}
                    >
                        <div className="round-value">
                            Раунд <span>{props.roundValue}</span>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
            <div className={timeStyle}>
                <span>{props.seconds}</span>
            </div>
            <div className="statistic">
                <div className="stat-block attempts">
                    <div className="value">
                        <img className="icon" src="/images/icons/checked.svg" alt=""/>
                        {props.rightAnswersCount}
                    </div>
                    <span>угадано</span>
                </div>
                <div className="stat-block attempts">
                    <div className="value">
                        <img className="icon" src="/images/icons/dice.svg" alt=""/>
                        {props.answersCount}
                    </div>
                    <span>ходов</span>
                </div>
                <div className="stat-block points">
                    <div className="value">
                        <img className="icon" src="/images/icons/star.svg" alt=""/>
                        {props.points}
                    </div>
                    <span>очков</span>
                </div>
            </div>
        </div>
    );
}

GamePanelUI.propTypes = {
    seconds: PropTypes.number.isRequired,
    roundValue: PropTypes.number.isRequired,
    rightAnswersCount: PropTypes.number.isRequired,
    answersCount: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired
};

export default GamePanelUI;