import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './store';

import { routerMiddleware } from 'connected-react-router';

let store;
let history;

export function configureStore(appHistory = null) {
    history = appHistory;

    store = createStore(rootReducer(history), applyMiddleware(routerMiddleware(history), thunk));

    return store;
}

export function getHistory() {
    return history;
}

export function getStore() {
    return store;
}
