class GameModel {
    constructor(data) {
        this.id = data.id;
        this.type = data.type;
        this.firstUserId = data.first_user_id;
        this.firstSocketId = data.first_socket_id;
        this.secondUserId = data.second_user_id;
        this.secondSocketId = data.second_socket_id;
        this.firstUserScore = data.first_user_score;
        this.secondUserScore = data.second_user_score;
        this.createdAt = data.created_at;
        this.closedAt = data.closed_at;
        this.closedReason = data.closed_reason;
        this.status = data.status;
        this.pin = data.pin;
        this.firstUserPoints = data.first_user_points;
        this.secondUserPoints = data.second_user_points;
        this.withBot = data.with_bot;
        this.timer = data.timer;
    }
}

export default GameModel;
