import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import GamePanelUI from '../../../../../components/Pages/Game/GamePanel';
import { EVENT_SERVER_GAME_TIMER } from '../../../../../config/socket';
import { setTimerValue } from '../../../../../store/game';
import PlayUser from '../../../../../model/playUser';

class SingleGamePanel extends PureComponent {
    constructor(props) {
        super(props);

        this.onTimer = this.onTimer.bind(this);
    }

    componentDidMount() {
        this.props.socket.on(EVENT_SERVER_GAME_TIMER, this.onTimer);
    }

    componentWillUnmount() {
        this.props.socket.off(EVENT_SERVER_GAME_TIMER, this.onTimer);
    }

    render() {
        const {rightAnswersCount, answersCount, points} = this.props.playerUserStat;

        return (
            <GamePanelUI
                seconds={this.props.timer}
                roundValue={this.props.roundValue}
                rightAnswersCount={rightAnswersCount}
                answersCount={answersCount}
                points={points}
            />
        );
    }

    /**
     *
     * @param response
     * @param response.timer
     */
    onTimer(response) {
        this.props.actions.setTimerValue(response.timer);
    }
}

SingleGamePanel.propTypes = {
    playerUserStat: PropTypes.instanceOf(PlayUser).isRequired,
    roundValue: PropTypes.number.isRequired
};

const mapStateToProps = function (state) {
    return {
        user: state.profile.info,
        timer: state.game.timer,
        socket: state.app.socket
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators({
            setTimerValue
        }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleGamePanel);
