import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PageProfileUI from '../../../components/Pages/Profile';
import { URL_HOME, URL_MONEY, URL_PROFILE } from '../../../config/url';
import { goToPage, showInvite, TYPE_FB, TYPE_VK, TYPE_VK_APP } from '../../../store/app';
import { GROUP_VK_URL } from '../../../config/social';
import { fetchLastProfileGames, fetchProfile } from '../../../store/profile';

class PageProfile extends PureComponent {
    constructor(props) {
        super(props);

        this.handlePageClose = this.handlePageClose.bind(this);
        this.handleInviteFriends = this.handleInviteFriends.bind(this);
        this.handleBuyCoins = this.handleBuyCoins.bind(this);
        this.handleClubJoin = this.handleClubJoin.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchProfile();
        this.props.actions.fetchLastProfileGames();
    }

    render() {
        const canSendInvite = [TYPE_VK, TYPE_VK_APP, TYPE_FB].indexOf(this.props.appType) !== -1;
        const canBuyCoins = this.props.isBuyMoneyAllowed || this.props.isAllowSeeAdv;

        return (
            <PageProfileUI
                onPageClose={this.handlePageClose}
                onInviteFriends={canSendInvite ? this.handleInviteFriends : undefined}
                //onGroupJoin={ !canSendInvite ? this.handleClubJoin : null}
                onBuyCoins={canBuyCoins ? this.handleBuyCoins : undefined}
                isOwnProfile={true}
                pageTitle={'Ваш профиль'}
                user={this.props.ownProfile}
                loadingText={'Загрузка профиля'}
                lastGames={this.getLastGames()}
            />
        );
    }

    handlePageClose() {
        this.props.actions.goToPage(URL_HOME);
    }

    getLastGames() {
        if (!this.props.lastGames) {
            return [];
        }

        return this.props.lastGames.map((game) /** @param game GameResultModel */ => {
            return {
                key: game.id,
                game,
                onFirstPlayerClick: this.handleOpenUser.bind(this, game.firstPlayer),
                onSecondPlayerClick: this.handleOpenUser.bind(this, game.secondPlayer),
            };
        });
    }

    handleOpenUser(gameUser) {
        this.props.actions.goToPage(`${URL_PROFILE}/${gameUser.id}`, {
            user: gameUser,
            hasBack: true,
        });
    }

    handleInviteFriends() {
        this.props.actions.showInvite(this.props.appType);
    }

    handleBuyCoins() {
        this.props.actions.goToPage(URL_MONEY);
    }

    handleClubJoin() {
        window.open(GROUP_VK_URL, '_blank');
    }
}

const mapStateToProps = function (state) {
    return {
        ownProfile: state.profile.info,
        lastGames: state.profile.lastGames,
        appType: state.app.appType,
        isBuyMoneyAllowed: state.app.isBuyMoneyAllowed,
        isAllowSeeAdv: state.app.isAllowSeeAdv,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                goToPage,
                showInvite,
                fetchLastProfileGames,
                fetchProfile,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageProfile);
