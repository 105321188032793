import React from 'react';
import PropTypes from 'prop-types';
import UserModel from '../../../model/user';

function TopUser(props) {
    const user = props.user;

    return (
        <div className="top-user" onClick={props.onClick}>
            <div className="user-photo">
                <span className="image" style={{ backgroundImage: `url('${user.photoUrl}')` }} />
            </div>
            <span className="user-name">{user.firstName}</span>
        </div>
    );
}

TopUser.propTypes = {
    user: PropTypes.instanceOf(UserModel),
    onClick: PropTypes.func,
};

export default TopUser;
