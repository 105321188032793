import React from 'react';
import PropTypes from 'prop-types';
import {
    SECONDS_10,
    SECONDS_20,
    OPEN_ONE_PAIR,
    SHOW_3_SEC,
    SECONDS_M5,
    SECONDS_M10,
} from '../../../../../config/helpers';
import './style.scss';
import className from 'classnames';
import ReactTooltip from 'react-tooltip';

function HelperItemUI(props) {
    const renderValue = (id) => {
        switch (id) {
            case SECONDS_10.id:
                return (
                    <div className={'text'}>
                        <span>+</span>10
                    </div>
                );
            case SECONDS_20.id:
                return (
                    <div className={'text'}>
                        <span>+</span>20
                    </div>
                );
            case SECONDS_M5.id:
                return (
                    <div className={'text'}>
                        <span>+</span>5
                    </div>
                );
            case SECONDS_M10.id:
                return (
                    <div className={'text'}>
                        <span>+</span>10
                    </div>
                );
            case SHOW_3_SEC.id:
                return <img className={'icon'} src={'/images/icons/visible.svg'} alt={''} />;
            case OPEN_ONE_PAIR.id:
                return <img className={'icon'} src={'/images/icons/star-magic.svg'} alt={''} />;
            default:
        }
    };

    const classes = className({
        'is-active': props.isActive,
        'is-disabled': !props.isActive,
        'helper-item': true,
    });

    const id = `${props.id}-tooltip`;

    return (
        <React.Fragment>
            <div
                className={classes}
                onClick={props.isActive ? props.onClick : undefined}
                data-tip
                data-for={id}
            >
                {renderValue(props.id)}
            </div>
            <ReactTooltip id={id} place="top" type="light" effect="solid">
                {props.title}
            </ReactTooltip>
        </React.Fragment>
    );
}

HelperItemUI.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
};

export default HelperItemUI;
