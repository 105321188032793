import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import PageCrossUI from '../../UI/PageCross';
import LoadingUI from '../../Loading';

import ShopGroupUI from '../Shop/Group';
import ShopItemUI from '../Shop/Item';

function PageShopUI(props) {
    const renderItems = () => {
        return (
            <div className="shop-container">
                {props.groups.map((group) => {
                    const items = group.items.map((element) => (
                        <ShopItemUI
                            key={element.key}
                            title={element.title}
                            description={element.description}
                            onClick={element.onClick}
                            isLoading={element.isLoading}
                            isDisabled={element.isDisabled}
                        />
                    ));

                    return (
                        <ShopGroupUI
                            key={group.key}
                            title={group.title}
                            description={group.description}
                            type={group.type}
                            items={items}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div id="page-shop" className="page with-background">
            <PageCrossUI onClick={props.onPageClose} />
            <h1>Магазин бонусов</h1>
            {props.isLoading ? (
                <LoadingUI loadingText={props.loadingText} />
            ) : (
                renderItems(props.items)
            )}
        </div>
    );
}

PageShopUI.propTypes = {
    onPageClose: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    title: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                    type: PropTypes.string.isRequired,
                    isLoading: PropTypes.bool.isRequired,
                    isDisabled: PropTypes.bool.isRequired,
                    onClick: PropTypes.func.isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
    isLoading: PropTypes.bool,
};

export default PageShopUI;
