import UserModel from './user';

class RatingUserModel {
    position = null;

    points = null;

    gamesWin = null;

    gamesCount = null;

    user = null;

    constructor(userData) {
        this.position = userData.rating_position;
        this.points = userData.rating_points;
        this.gamesWin = userData.rating_games_win;
        this.gamesCount = userData.rating_games_count;

        this.user = new UserModel(userData.user);
    }
}

export default RatingUserModel;
