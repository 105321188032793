import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';
import ButtonUI from '../UI/Button';
import LoadingUI from '../Loading';

const AuthUI = function (props) {
    const classes = classNames({
        'auth-block': true,
        'is-blocked': props.isLoading,
    });

    return (
        <div id="auth-page">
            <div className={classes}>
                <div className="app-logo">
                    <img src={'/images/logo.png'} alt={''} />
                </div>
                <div className={'methods'}>
                    <h2>Авторизация</h2>

                    <div className={'buttons'}>
                        <ButtonUI
                            text={'Вконтакте'}
                            className="vk"
                            icon={'/images/icons/vk.svg'}
                            onClick={props.onVKClick}
                        />
                        <ButtonUI
                            text={'Одноклассники'}
                            className="ok"
                            icon={'/images/icons/odnoklassniki.svg'}
                            onClick={props.onOKClick}
                        />
                        <ButtonUI
                            text={'Facebook'}
                            className="fb"
                            icon={'/images/icons/facebook.svg'}
                            onClick={props.onFBClick}
                        />
                    </div>
                    {props.isLoading ? (
                        <div className={'blocker'}>
                            <LoadingUI />
                        </div>
                    ) : null}
                </div>
            </div>
            <footer>{props.footer}</footer>
        </div>
    );
};

AuthUI.propTypes = {
    isLoading: PropTypes.bool,
    footer: PropTypes.element,
    onVKClick: PropTypes.func,
    onOKClick: PropTypes.func,
    onFBClick: PropTypes.func,
};

export default AuthUI;
