class PlayUserModel {
    userId = null;

    answersCount = 0;

    rightAnswersCount = 0;

    points = 0;

    pointsWithBonuses = 0;

    isWinner = false;

    photo = null;

    name = null;

    constructor(data) {
        this.userId = data.userId;
        this.answersCount = data.answersCount || 0;
        this.rightAnswersCount = data.rightAnswersCount || 0;
        this.points = data.points || 0;
        this.isWinner = data.isWinner || false;
        this.photo = data.photo || null;
        this.pointsWithBonuses = data.pointsWithBonuses || 0;
        this.name = data.name || null;
    }
}

export default PlayUserModel;
