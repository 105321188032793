import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { removeNotification } from '../../store/notification';
import NotificationContainerUI, { ANIMATION_TIME } from '../../components/NotificationContainer';

class Notification extends PureComponent {
    constructor() {
        super();

        this.state = {
            activeNotification: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notifications.length !== this.props.notifications.length) {
            setTimeout(() => {
                this.processNotification();
            }, ANIMATION_TIME);
        }
    }

    render() {
        const notification = this.state.activeNotification;

        return <NotificationContainerUI notification={notification} />;
    }

    processNotification() {
        if (!this.state.activeNotification) {
            const activeNotification = this.props.notifications[0];

            if (activeNotification) {
                this.setState({
                    activeNotification,
                });

                setTimeout(() => {
                    this.setState({
                        activeNotification: null,
                    });

                    this.props.actions.removeNotification(activeNotification);
                }, activeNotification.timeout);
            }
        }
    }
}

const mapStateToProps = function (state) {
    return {
        notifications: state.notification.notifications,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                removeNotification,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
