import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FriendGame from './Friend';
import RandomGame from './Random';
import SingleGame from './Single';
import Play from './Play';

import { GAME_TYPE_FRIEND, GAME_TYPE_RANDOM, GAME_TYPE_SINGLE } from '../../../config';

import {
    EVENT_CLIENT_START_NEW_GAME,
    EVENT_SERVER_GAME_SINGLE_FINISHED,
    EVENT_SERVER_MULTIPLAYER_GAME_FINISHED,
    EVENT_SERVER_GAME_USER_LEFT_GAME,
    EVENT_SERVER_GAME_STARTED,
    EVENT_CLIENT_LEAVE,
} from '../../../config/socket';

import { setIsGameStarted, setTimerValue, setPlayUser, setActiveUserId } from '../../../store/game';
import { setUser } from '../../../store/profile';
import { showDialog } from '../../../store/dialog';
import { DIALOG_SINGLE_GAME_FINISH } from '../../Dialog/Types/SingleGameFinish';
import { DIALOG_MULTIPLAYER_GAME_FINISH } from '../../Dialog/Types/MultiplayerGameFinish';
import { DIALOG_USER_DISCONNECTED } from '../../Dialog/Types/UserDisconnected';
import GameModel from '../../../model/game';
import RoundModel from '../../../model/round';
import PlayUser from '../../../model/playUser';
import { URL_HOME } from '../../../config/url';
import { goToPage, STATE_BACKGROUND } from '../../../store/app';
import { getPixelRatio } from '../../../utils';
import {
    sendReachGoal,
    EVENT_START_GAME,
    EVENT_START_FRIEND_GAME,
    EVENT_START_SINGLE,
} from '../../../store/statistic';

class PageGame extends PureComponent {
    static navigationOptions = () => {
        return {
            header: null,
        };
    };

    constructor(props) {
        super(props);

        this.handleStartGame = this.handleStartGame.bind(this);
        this.onGameStarted = this.onGameStarted.bind(this);
        this.onGameSingleFinished = this.onGameSingleFinished.bind(this);
        this.onGameMultiplayerFinished = this.onGameMultiplayerFinished.bind(this);
        this.onUserLeftGame = this.onUserLeftGame.bind(this);
        this.handleNewSingleGame = this.handleNewSingleGame.bind(this);
        this.handleNewMultiplayerGame = this.handleNewMultiplayerGame.bind(this);
    }

    componentDidMount() {
        this.props.socket.on(EVENT_SERVER_GAME_STARTED, this.onGameStarted);
        this.props.socket.on(EVENT_SERVER_GAME_SINGLE_FINISHED, this.onGameSingleFinished);
        this.props.socket.on(
            EVENT_SERVER_MULTIPLAYER_GAME_FINISHED,
            this.onGameMultiplayerFinished
        );
        this.props.socket.on(EVENT_SERVER_GAME_USER_LEFT_GAME, this.onUserLeftGame);
    }

    componentWillUnmount() {
        this.props.socket.off(EVENT_SERVER_GAME_STARTED, this.onGameStarted);
        this.props.socket.off(EVENT_SERVER_GAME_SINGLE_FINISHED, this.onGameSingleFinished);
        this.props.socket.off(
            EVENT_SERVER_MULTIPLAYER_GAME_FINISHED,
            this.onGameMultiplayerFinished
        );
        this.props.socket.off(EVENT_SERVER_GAME_USER_LEFT_GAME, this.onUserLeftGame);

        this.props.actions.setIsGameStarted(false);

        this.leaveFromGame();
    }

    componentDidUpdate() {
        if (this.props.appState === STATE_BACKGROUND) {
            this.props.actions.goToPage(URL_HOME);
        }
    }

    getGameType() {
        if (this.props.match) {
            return this.props.match.params.type;
        } else if (this.props.navigation) {
            return this.props.navigation.getParam('type');
        }
    }

    leaveFromGame() {
        if (!this.props.game) {
            // Exit event when user does not have active game
            const userId = this.props.user.id;
            this.props.socket.emit(EVENT_CLIENT_LEAVE, {
                userId,
            });
        }
    }

    render() {
        if (this.props.isGameStarted) {
            return <Play />;
        } else {
            switch (this.getGameType()) {
                case GAME_TYPE_FRIEND:
                    return (
                        <FriendGame
                            onStart={this.handleStartGame}
                            navigation={this.props.navigation}
                        />
                    );
                case GAME_TYPE_RANDOM:
                    return (
                        <RandomGame
                            onStart={this.handleStartGame}
                            navigation={this.props.navigation}
                        />
                    );
                default:
                    return (
                        <SingleGame
                            onStart={this.handleStartGame}
                            navigation={this.props.navigation}
                        />
                    );
            }
        }
    }

    handleStartGame(gameType, pin, gameId) {
        this.props.socket.emit(EVENT_CLIENT_START_NEW_GAME, {
            userId: this.props.user.id,
            gameType,
            pin,
            gameId,
        });

        switch (gameType) {
            case GAME_TYPE_FRIEND:
                this.props.actions.sendReachGoal(EVENT_START_FRIEND_GAME);
                break;
            case GAME_TYPE_RANDOM:
                this.props.actions.sendReachGoal(EVENT_START_GAME);
                break;
            case GAME_TYPE_SINGLE:
                this.props.actions.sendReachGoal(EVENT_START_SINGLE);
                break;
            default:
        }
    }

    handleNewSingleGame() {
        this.props.actions.setIsGameStarted(false);
    }

    handleNewMultiplayerGame() {
        this.props.actions.setIsGameStarted(false);
    }

    /**
     *
     * @param data
     * @param data.playUser
     */
    onGameSingleFinished({ playUser }) {
        this.props.actions.showDialog(DIALOG_SINGLE_GAME_FINISH, {
            playUser: new PlayUser(playUser),
            onNewGame: this.handleNewSingleGame,
        });
    }

    /**
     *
     * @param data
     * @param data.playUser
     * @param data.isDraw
     */
    onGameMultiplayerFinished({ playUser, isDraw }) {
        this.props.actions.showDialog(DIALOG_MULTIPLAYER_GAME_FINISH, {
            playUser: new PlayUser(playUser),
            isDraw,
            onNewGame: this.handleNewMultiplayerGame,
        });
    }

    /**
     *
     * @param data
     * @param data.playUser
     * @param data.disconnectedUserId
     */
    onUserLeftGame({ playUser, disconnectedUserId }) {
        this.props.actions.showDialog(DIALOG_USER_DISCONNECTED, {
            playUser: new PlayUser(playUser),
            disconnectedUserId,
            onNewGame: this.handleNewMultiplayerGame,
        });
    }

    /**
     *
     * @param response
     * @param response.game
     * @param response.timer
     * @param response.round
     * @param response.activeUserId
     * @param response.playUsers
     */
    onGameStarted({ game, timer, round, activeUserId, playUsers }) {
        const gameModel = new GameModel(game);
        const roundModel = new RoundModel(round, getPixelRatio());

        playUsers.forEach((user) => {
            this.props.actions.setPlayUser(user.userId, new PlayUser(user));
        });

        this.props.actions.setActiveUserId(activeUserId);
        this.props.actions.setTimerValue(timer);
        this.props.actions.setIsGameStarted(true, gameModel, roundModel);
    }
}

const mapStateToProps = function (state) {
    return {
        appState: state.app.appState,
        user: state.profile.info,
        isGameStarted: state.game.isGameStarted,
        socket: state.app.socket,
        game: state.game.game,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                setIsGameStarted,
                setUser,
                setTimerValue,
                showDialog,
                setPlayUser,
                setActiveUserId,
                goToPage,
                sendReachGoal,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageGame);
