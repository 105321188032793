import MoneyModel from '../../model/money';
import axios from 'axios';
import { SERVER_API } from '../../config';

const SET_MONEY_ITEMS = Symbol('SET_MONEY_ITEMS');

function setMoneyItems(items) {
    return {
        type: SET_MONEY_ITEMS,
        items,
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');

function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

export function fetchMoneyItems(type) {
    return function (dispatch) {
        dispatch(setIsLoading(true));

        return axios
            .get(`${SERVER_API}/money`, {
                params: {
                    type,
                },
            })
            .then(function (response) {
                if (response.data.items) {
                    const items = response.data.items.map((item) => {
                        return new MoneyModel(item);
                    });

                    dispatch(setMoneyItems(items));
                    dispatch(setIsLoading(false));
                }
            });
    };
}

const initialState = {
    items: [],
    isLoading: false,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_MONEY_ITEMS] = function () {
        return {
            ...state,
            items: action.items,
        };
    };

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
