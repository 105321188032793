import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToPage, TYPE_NATIVE } from '../../store/app';

import TopUsersUI from '../../components/TopUsers';

import { fetchTopUsers } from '../../store/top';
import { URL_PROFILE, URL_RATING_USER } from '../../config/url';

const REFETCH_TIMER = 1000 * 60 * 5;

class TopUsers extends PureComponent {
    constructor(props) {
        super(props);

        this._interval = null;
    }

    componentDidMount() {
        this._interval = setInterval(() => {
            this.props.actions.fetchTopUsers();
        }, REFETCH_TIMER);
    }

    componentWillUnmount() {
        clearInterval(this._interval);
    }

    render() {
        const users = this.props.topUsers.map((ratingUser) => {
            return {
                user: ratingUser.user,
                onClick: this.handleOpenProfile.bind(this, ratingUser.user),
            };
        });

        return <TopUsersUI users={users} />;
    }

    handleOpenProfile(user) {
        if (this.props.appType === TYPE_NATIVE) {
            this.props.navigation.navigate(URL_RATING_USER, { user });
        } else {
            this.props.actions.goToPage(`${URL_PROFILE}/${user.id}`, { user });
        }
    }
}

const mapStateToProps = function (state) {
    return {
        topUsers: state.top.users,
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                fetchTopUsers,
                goToPage,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopUsers);
