class MoneyModel {
    id = null;

    title = null;

    price = null;

    oldPrice = null;

    type = null;

    helperText = null;

    image = null;

    constructor(data) {
        this.id = data.id;
        this.title = data.title;
        this.price = data.price;
        this.oldPrice = data.old_price;
        this.type = data.type;
        this.helperText = data.helper_text;
        this.image = data.image;
    }
}

export default MoneyModel;
