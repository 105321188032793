import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Button from '../../../UI/Button';
import classNames from 'classnames';

function UserDisconnectedUI(props) {
    const classes = classNames({
        'dialog-content': true,
        'dialog-round-finished': true,
        'dialog-user-disconnected': true,
    });

    return (
        <div className={classes}>
            <h2>Соперник отключился</h2>

            <div className={'game-info'}>
                <div className="stat-block points">
                    <div className="value">
                        <img className="icon" src="/images/icons/star-colour.svg" alt="" />
                        {props.points}
                    </div>
                    <span>Заработанные очки</span>
                </div>
            </div>

            <div className={'buttons'}>
                <Button text={'Новая игра'} onClick={props.onContinueClick} />
                <div className="sub-button" onClick={props.onCancelClick}>
                    Завершить
                </div>
            </div>
        </div>
    );
}

UserDisconnectedUI.propTypes = {
    onContinueClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    points: PropTypes.number,
};
export default UserDisconnectedUI;
