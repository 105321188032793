export const EVENT_SERVER_READY = 'ready';
export const EVENT_SERVER_GAME_STARTED = 'game:started';
export const EVENT_SERVER_GAME_TIMER = 'game:timer';
export const EVENT_SERVER_GAME_SINGLE_FINISHED = 'game:singleFinished';
export const EVENT_SERVER_MULTIPLAYER_GAME_FINISHED = 'game:multiplayerFinished';
export const EVENT_SERVER_GAME_NEW_ROUND = 'game:newRound';
export const EVENT_SERVER_GAME_ROUND_RESULT = 'game:roundResult';
export const EVENT_SERVER_GAME_UPDATE_HELPERS = 'game:updateHelpers';
export const EVENT_SERVER_GAME_HELPER_DATA = 'game:helperData';
export const EVENT_SERVER_GAME_PLAYER_OPEN_ITEM = 'game:showOpenPosition';
export const EVENT_SERVER_GAME_ACTIVE_USER_ID = 'game:setActiveUserId';
export const EVENT_SERVER_GAME_USER_LEFT_GAME = 'game:userLeftGame';
export const EVENT_SERVER_UPDATE_USER = 'user:update';
export const EVENT_SERVER_GAME_ONLINE = 'game:online';

export const EVENT_CLIENT_START_NEW_GAME = 'game:new';
export const EVENT_CLIENT_GAME_LEAVE = 'game:leave';
export const EVENT_CLIENT_GAME_PLAYER_READY = 'game:playerReady';
export const EVENT_CLIENT_GAME_PLAYER_MOVE = 'game:playerMove';
export const EVENT_CLIENT_GAME_USE_HELPER = 'game:useHelper';
export const EVENT_CLIENT_GAME_PLAYER_OPEN_ITEM = 'game:openPosition';
export const EVENT_CLIENT_LEAVE = 'leave';
