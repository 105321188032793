import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Button from '../../../UI/Button';
import classNames from 'classnames';

function UserDisconnectedUI({ title, message, onContinueClick }) {
    const classes = classNames({
        'dialog-content': true,
        'dialog-round-finished': true,
        'dialog-alert': true,
    });

    return (
        <div className={classes}>
            <h2>{title}</h2>

            <div className={'error-block'}>
                <div className="message">{message}</div>
            </div>

            <div className={'buttons'}>
                <Button text={'Продолжить'} onClick={onContinueClick} />
            </div>
        </div>
    );
}

UserDisconnectedUI.propTypes = {
    onContinueClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
};
export default UserDisconnectedUI;
