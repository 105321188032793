import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import UserModel from '../../model/user';
import UserLevelProgressUI from '../UI/LevelProgress';
import UserInfoUI from '../UI/UserInfo';
import i18n from '../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function HeaderUI(props) {
    const { user, hasMoneyTooltip, onMoneyTooltipClick, onMoneyTooltipClose } = props;

    return (
        <div className="user-header">
            <UserInfoUI photoUrl={user.photoUrl} level={user.level} />

            <UserLevelProgressUI
                levelPoints={props.user.levelPoints}
                levelNeedPoints={props.user.levelNeedPoints}
                totalPoints={props.user.totalPoints}
            />

            <div className="user-money" onClick={props.onMoneyClick ? props.onMoneyClick : null}>
                <img className="icon-money" src={'/images/icons/coins.svg'} alt="" />
                <span className="text">{i18n.t('coinsCount', { count: props.user.money })}</span>
                {props.onMoneyClick ? <span className="icon-add">+</span> : null}
                {hasMoneyTooltip ? (
                    <div className="money-info" onClick={onMoneyTooltipClick}>
                        <span className="money-info-text">
                            Мало монет? <br /> Добавь для подсказок
                        </span>
                        <span className="money-info-close" onClick={onMoneyTooltipClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    </div>
                ) : null}
            </div>

            <div className="game-block">
                {props.onGroupClick ? (
                    <div onClick={props.onGroupClick} className={'item game-group'}>
                        <img className="icon" src={'/images/icons/public.svg'} />
                        <span>Вступить</span>
                    </div>
                ) : null}

                {props.onShareClick ? (
                    <div onClick={props.onShareClick} className={'item game-help'}>
                        <img className="icon" src={'/images/icons/share.svg'} />
                        <span>Поделиться</span>
                    </div>
                ) : null}

                {props.onInviteClick ? (
                    <div onClick={props.onInviteClick} className="item game-invite">
                        <img className="icon" src={'/images/icons/invite-user.svg'} />
                        <span>Пригласить</span>
                    </div>
                ) : null}

                <div onClick={props.onHelpClick} className={'item game-help'}>
                    <img className="icon" src={'/images/icons/info.svg'} />
                    <span>Помощь</span>
                </div>

                {props.onLogoutClick ? (
                    <div onClick={props.onLogoutClick} className="item game-logout">
                        <img className="icon" src={'/images/icons/logout.svg'} />
                        <span>Выход</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

HeaderUI.propTypes = {
    user: PropTypes.instanceOf(UserModel),
    onShareClick: PropTypes.func,
    onInviteClick: PropTypes.func,
    onHelpClick: PropTypes.func,
    onMoneyClick: PropTypes.func,
    onLogoutClick: PropTypes.func,
    onGroupClick: PropTypes.func,
    hasMoneyTooltip: PropTypes.bool,
    onMoneyTooltipClose: PropTypes.func,
    onMoneyTooltipClick: PropTypes.func,
};

export default HeaderUI;
