export const URL_HOME = '/';
export const URL_SHARE = '/share';
export const URL_HELP = '/help';
export const URL_MONEY = '/money';
export const URL_RATING = '/rating';
export const URL_RATING_USER = '/rating/user';
export const URL_INVITE = '/invite';
export const URL_SHOP = '/shop';
export const URL_PROFILE = '/profile';
export const URL_GAME = '/game';
export const URL_AUTH_PROVIDER = '/auth-provider';
export const URL_AUTH = '/auth';
export const URL_JOIN = '/join';
export const URL_PRIVACY = '/privacy';
